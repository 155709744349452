.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  @include border-radius(2px !important);
  padding: 0;

  &:hover {
    background-color: $bg-color-blue;
  }
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  &:hover {
    color: $txt-color-red;
  }
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
  &:hover {
    color: $txt-color-blue;
  }
}

@media only screen and (max-width: 1024px) {
  .create-form {
    .content {
      max-width: 95%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .create-form {
    .content {
      max-width: 100%;
      //margin-right: 24px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .create-form {
    .content {
      max-width: 100%;
      //margin-right: 16px;
    }
  }
}
