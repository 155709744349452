.gap-2 {
  gap: 8px;
}

.gap-3 {
  gap: 12px;
}

.flex-1 {
  flex: 1;
}