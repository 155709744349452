.comment-box {
  background: #FFFFFF;
  /* BG Shadow */

  box-shadow: 0 2px 14px #E6E6E6;
  border-radius: 6px;
  padding: 8px 12px 0;
  position: relative;
  padding-bottom: 0;

  .header-comment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .title {
      font-size: 14px;
      color: #333333;
      font-weight: 600;
    }

    .icon {
      font-size: 14px;
      color: #BDBDBD;
      font-weight: 900;
      cursor: pointer;
    }
  }

  .comment-line {
    height: 1px;
    width: 100%;
    background-color: #007574;
    margin-bottom: 6px;
    margin-top: 3px;
  }

  .comment-content::-webkit-scrollbar {
    /* This is the magic bit for WebKit */
    display: none;
  }

  .comment-content {
    margin-top: 60px;

    .empty-data {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .empty-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        color: #BDBDBD;

        .icon {
          font-size: 48px;
          color: #EDF3F7
        }

        .text-empty {
          font-size: 12px;
          line-height: 1.34;
        }

        .text-empty-shortcut {
          font-size: 12px;
          line-height: 1.34;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    .comment-item-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .comment-item-right-wrapper {
        margin-bottom: 4px;

        .comment-item-header {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          //margin-bottom: 3px;

          .comment-time {
            font-size: 10px;
            color: #BDBDBD;
            margin-right: 8px;
          }

          .comment-user {
            font-weight: 500;
            color: #1A1A1A;
            font-size: 12px;
          }
        }

        .comment-item-content {
          flex-direction: row;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .icon-copy {
            font-size: 14px;
            color: #008DF9;
            cursor: pointer;
          }

          .content-link {
            background: #DDEFEF;
            border-radius: 4px;
            padding: 6px;
            margin-left: 6px;
            max-width: 80%;
            word-break: break-all;
          }


          .content {
            background: #DDEFEF;
            border-radius: 4px;
            font-size: 12px;
            padding: 6px;
            margin-left: 6px;
            max-width: 80%;

            //word-break: break-all;
          }
        }

        .comment-item-media {
          .file-list {
            .file {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              .file-wrap {
                background: #DDEFEF;
                border-radius: 4px;
                padding: 8px;
                margin-right: 6px;
                margin-bottom: 0;
                max-width: 100%;

                .file-name {
                  text-decoration: underline;
                  border-radius: 4px;
                  //padding-top: 8px;
                  //padding-bottom: 8px;
                  //padding-left: 8px;
                  max-width: 100%;
                  font-size: 12px;
                  display: inline-block;
                  word-break: break-all;
                }
              }

              .btn {
                //margin-left: 2px;
                color: #008DF9;
                font-size: 11px;
              }
            }
          }

          .image-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-bottom: 6px;

            .image {
              cursor: pointer;
              width: 56px;
              height: 56px;
              margin-right: 6px;
              position: relative;
              border-radius: 4px;
              margin-bottom: 6px;
            }
          }
        }
      }


    }

    .comment-item-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .comment-item-left-wrapper {
        margin-bottom: 6px;

        .comment-item-header {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          //margin-bottom: 3px;

          .comment-time {
            font-size: 11px;
            color: #BDBDBD;
          }

          .comment-user {
            color: #007574;
            font-size: 12px;
            font-weight: 500;
            margin-right: 8px;

          }
        }

        .comment-item-content {
          flex-direction: row;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .content-link {
            background: #EBEBEB;
            border-radius: 4px;
            padding: 6px;
            margin-right: 6px;
            max-width: 80%;
            //overflow: hidden;
            //white-space: nowrap;
            //text-overflow: ellipsis;
            word-break: break-all;
          }

          .content {
            background: #EBEBEB;
            border-radius: 4px;
            padding: 6px;
            font-size: 12px;
            margin-right: 6px;
            max-width: 80%;
          }

          .icon-copy {
            font-size: 14px;
            color: #008DF9;
            cursor: pointer;
          }
        }

        .comment-item-media {
          .file-list {
            .file {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              .file-wrap {
                background: #DDEFEF;
                border-radius: 4px;
                padding: 8px;
                //margin-right: 6px;
                margin-bottom: 6px;
                max-width: 100%;

                .file-name {
                  text-decoration: underline;
                  border-radius: 4px;
                  //padding-top: 8px;
                  //padding-bottom: 8px;
                  //padding-left: 8px;
                  max-width: 100%;
                  display: inline-block;
                  word-break: break-all;
                }
              }

              .btn {
                //margin-left: 2px;
                color: #008DF9;
                font-size: 12px;
              }
            }
          }

          .image-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            .image {
              cursor: pointer;
              width: 56px;
              height: 56px;
              margin-right: 6px;
              position: relative;
              border-radius: 4px;
              margin-bottom: 6px;
            }
          }
        }
      }
    }
  }


  .comment-footer {
    position: absolute;
    top: 40px;
    left: 12px;
    right: 12px;
    padding-bottom: 4px;
    background-color: #fff;
    border-radius: 6px;

    .content {
      height: 100%;
      display: flex;
      flex-direction: row;

      .file {
        cursor: pointer;
        display: flex;
        border-radius: 4px;
        border: 1px solid #C7C7C7;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 38px;
        height: 36px;
        margin-right: 8px;
      }

      .content-input {
        width: 88%;

        .wrapper-input {
          border-radius: 4px;
          border: 1px solid #C7C7C7;
          overflow-y: auto;
          outline: 0;
          max-width: 100%;
          min-height: 36px;

          [contenteditable=true]:empty:before {
            content: attr(placeholder);
            pointer-events: none;
            display: block; /* For Firefox */
            color: #BDBDBD;
            font-size: 12px;
            align-self: center;
            padding-top: 2px;
            -webkit-user-select: auto;
            user-select: auto;
            width: 100% !important;
            text-overflow: ellipsis;
            @media (max-width: 767px) {
              display: none;
            }
          }

          /* */

          .input {
            //border: 0 !important;
            width: 90%;
            padding: 6px 10px;
            display: inline-block;
            font-size: 13px;
            line-height: 1.34;
            -webkit-user-select: auto;
            user-select: auto;
          }

          .image-list {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 4px;
            margin-right: 10px;
            margin-left: 10px;

            .image-preview {
              cursor: pointer;
              width: 56px;
              height: 56px;
              margin-right: 6px;
              position: relative;
              margin-bottom: 6px;

              .blur-bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                border: 0;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.05);
              }

              .img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
              }

              .close-icon {
                position: absolute;
                color: #FFFFFF;
                top: 4px;
                right: 4px;
                cursor: pointer;
              }
            }
          }

          .file-list {

            margin-left: 10px;
            margin-top: 6px;

            .file-preview {
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-content: center;
              align-items: center;
              margin-right: 50px;
              max-width: 100%;

              .file-name {
                margin-right: 6px;
                text-decoration: underline;
                //padding-top: 8px;
                //padding-bottom: 8px;
                //padding-left: 8px;
                max-width: 100%;
                display: inline-block;
                word-break: break-all;
              }

              .close-icon {
                color: #BDBDBD;
                cursor: pointer;
              }
            }
          }

        }

        .comment-input-icon-expand {
          position: absolute;
          right: 11px;
          top: 4px;
          bottom: 4px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          align-content: center;

          .icon {
            cursor: pointer;
            font-size: 14px;
            color: #008DF9;
          }

          .icon-expanded {
            cursor: pointer;
            font-size: 14px;
            color: #BDBDBD;
          }

        }

        .comment-input-icon {
          position: absolute;
          right: 11px;
          top: 10px;

          .icon {
            cursor: pointer;
            font-size: 14px;
            color: #008DF9;
          }
        }

        .wrapper-input::-webkit-scrollbar {
          /* This is the magic bit for WebKit */
          display: none;
        }


        .wrapper-input-expand-full {

        }

        .warning {
          color: #BDBDBD;
          font-weight: normal;
          font-size: 12px;
          margin-top: 6px;
        }
      }
    }
  }

}

@media only screen and (max-width: 1280px) {
  .comment-box .comment-footer .content .content-input .wrapper-input [contenteditable=true]:empty:before {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 102px;
  }
}
