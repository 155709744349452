.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 8px;
  top: 6px;
  padding: 0 6px;
  background: #fff;
  color: #7D7E7E;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input, .did-floating-select {
  font-size: 12px;
  display: inline-block;
  width: 100%;
  margin-top: 2px;
  background: transparent;
  box-sizing: border-box;

  &:focus {
    outline: none;
    ~ .did-floating-label {
      top: -12px;
      left: 8px;
      font-size: 12px;
      color: #007574;
    }
  }
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -12px;
  font-size: 12px;
}

.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px!important;
  font-size: 12px;
}

.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 12px;
}

.did-floating-select > .ant-select-selector >.ant-select-selection-item:not([value='']):valid ~ .did-floating-label {
  top: -14px;
  font-size: 12px;
}



.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.input-group {
  display: flex;
  .did-floating-input{
    border-radius:0 4px 4px 0;
    border-left:0;
    padding-left:0;
  }
}
.input-group-append {
  display:flex;
  align-items:center;
  /*   margin-left:-1px; */
}
.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height:34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size:12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3D85D8;
  border-radius: 4px 0 0 4px;
  border-right:none;
}
