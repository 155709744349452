.ant-tooltip-placement-top, .ant-tooltip-placement-left {

    .ant-tooltip-inner,
    .ant-tooltip-arrow-content {
        background-color: #007574;
        --antd-arrow-background-color: #007574;
        font-size: 12px;
        font-weight: 400;
    }
}

.ant-badge-count {
    @apply bg-primary;
}


