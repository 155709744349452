div.ant-modal-wrap {

    .ant-modal {
        .ant-modal-header {
            padding: 12px;
            font-size: 16px;
            text-transform: capitalize;
        }

        &.log-modal {
            .list-data {
                margin: 0;
                padding: 0;
                box-shadow: unset;
                border-radius: 0;
            }

            strong {
                font-weight: 500;
            }
        }
    }

    .ant-modal-body {
        padding: 12px 12px 6px 12px;
        max-height: 85vh;
        overflow: auto;

        .items {
            padding: 12px;
            // overflow-y: auto;
            // max-height: calc(100vh - 150px);
        }
        .actions {
            margin-top: 12px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
