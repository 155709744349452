.ant-picker-large .ant-picker-input>input {
    font-size: 14px;
    padding: 2px;
}

.ant-input-group {

    >div.float-label:first-child .ant-picker-large {
        border-radius: 8px 0 0 8px !important;
    }

    ;

    >div.float-label:last-child .ant-picker-large {
        border-radius: 0 8px 8px 0 !important;
    }


    >div.float-label:first-child .ant-input-number-lg {
        border-radius: 8px 0 0 8px !important;
    }

    >div.float-label:last-child .ant-input-number-lg {
        border-radius: 0 8px 8px 0 !important;
    }
}

.ant-input-number {
    font-size: 14px;
}

.ant-input-affix-wrapper {
    border-radius: 8px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #15827d;
    border-right-width: 1px;
    z-index: 0;
}