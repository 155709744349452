.group-states {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0;

  &_item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 2px 16px;
    cursor: pointer;
    background: #EBEBEB;
    margin-right: 16px;
    font-weight: 500;
    font-size: 12px;
    color: #5A5A5A;
  }

  .state-selected {
    background: #007574;
    color: #FFFFFF;
  }




}
