body {
    font-family: $robotofont !important;
}

//Color
.txt-color-blue {
    color: $txt-color-blue;
}

.txt-color-yellow {
    color: $txt-color-yellow;
}

.txt-color-black {
    color: $txt-color-black;
}

.txt-color-black2 {
    color: $txt-color-black2;
}

.txt-color-black3 {
    color: $txt-color-black3;
}

.txt-color-white {
    color: $txt-color-white;
}

.txt-color-gray {
    color: $txt-color-gray;
}

.txt-color-gray2 {
    color: $txt-color-gray2;
}

.txt-color-red {
    color: $txt-color-red;
}

.txt-color-green {
    color: $txt-color-green;
}

.txt-color-blue2 {
    color: $txt-color-blue2 !important;
}

.txt-color-orange {
    color: $txt-color-orange;
}

.txt-color-only-staff {
    color: $txt-color-only-staff;
}

.txt-color-only-staff {
    color: $txt-color-only-staff;
}

.txtprocessing {
    color: #b21cce;

    > span {
        color: #707070;
    }
}

.txtcreated {
    color: #008df9;
}

.txtwaiting {
    color: #d7c314;
}

.txtreceive {
    color: #f6ae2d;
}

.txtcomplaintcomment {
    color: #b10f2e;
}

.txtaddtransaction {
    color: #008df9;

    > span {
        color: #707070;
    }
}

.txtorigincode {
    color: #2ec4b6;
}

.txtrenew {
    color: #8367c7;
}

.txtupdatereason {
    color: #417b5a;
}

.txtupdatenote {
    color: #540fda;
}

.txtdeletetransaction {
    color: #ff4559;

    > span {
        color: #707070;
    }
}

.txt-color-red2 {
    color: $txt-color-red2;
}

.txtfailure {
    color: red;
}

.txtsuccess {
    color: #28a745;
}

.text-primary {
    color: $primary-color !important;
}

.text-secondary {
    color: $secondary-color;
}

.text-secondary-2 {
    color: $secondary-color-2;
}

//bg
.bgprocessing {
    background-color: #b21cce;
}

.bgcreated {
    background-color: #058c42;
}

.bgwaiting {
    background-color: #f26419;
}

.bgreceive {
    background-color: #f6ae2d;
}

.bgcomplaintcomment {
    background-color: #b10f2e;
}

.bgaddtransaction {
    background-color: #008df9;
}

.bgorigincode {
    background-color: #2ec4b6;
}

.bgrenew {
    background-color: #8367c7;
}

.bgupdatereason {
    background-color: #417b5a;
}

.bgupdatenote {
    background-color: #540fda;
}

.bgdeletetransaction {
    background-color: #ff4559;
}

.bgfailure {
    background-color: red;
}

.bgsuccess {
    background-color: #28a745;
}

.bgnew {
    background-color: #e9d315;
}

.bgaccepted {
    background-color: #52c41a;
}

.bgreject {
    background-color: red;
}

.bgrefund {
    background-color: #52c41a;
}

//Font
.robotoregular {
    font-family: $robotofont;
    font-weight: 400;
}

.robotomedium {
    font-family: $robotofont;
    font-weight: 500;
}

.robotobold {
    font-family: $robotofont;
    font-weight: 700;
}

//Background color
.bg-color-gray {
    background-color: $bg-color-gray;
}

.bg-color-gray2 {
    background-color: $bg-color-gray2;
}

.bg-color-gray3 {
    background-color: $bg-color-gray3;
}

.bg-color-gray4 {
    background-color: $bg-color-gray4;
}

.bg-color-white {
    background-color: $bg-color-white;
}

.bg-color-green {
    background-color: $bg-color-green;
}

.bg-color-green2 {
    background-color: $bg-color-green2;
}

.bg-color-blue {
    background-color: $bg-color-blue;
}

.bg-color-blue2 {
    background-color: $bg-color-blue2;
}

.bg-color-blue3 {
    background-color: $bg-color-blue3;
}

.bg-color-mix-blue {
    background-color: #a6d9f9;
}

//Font-size
.txt-size-h1 {
    font-size: $txt-size-h1;
}

.txt-size-h2 {
    font-size: $txt-size-h2;
}

.txt-size-h3 {
    font-size: $txt-size-h3;
}

.txt-size-h4 {
    font-size: $txt-size-h4;
}

.txt-size-h5 {
    font-size: $txt-size-h5;
}

.txt-size-h7 {
    font-size: $txt-size-h7;
}

.txt-size-h8 {
    font-size: $txt-size-h8;
}

.txt-size-h9 {
    font-size: $txt-size-h9;
}

//Padding
.pd30 {
    padding: 30px;
}

.pd20 {
    padding: 20px;
}

.pd24 {
    padding: 24px;
}

.pd15 {
    padding: 15px;
}

.pd16 {
    padding: 16px;
}

.pd10 {
    padding: 10px;
}

.pd8 {
    padding: 8px;
}

.pd5 {
    padding: 5px;
}

.pd4 {
    padding: 4px;
}

.pdl0 {
    padding-left: 0 !important;
}

.pdl5 {
    padding-left: 5px;
}

.pdl8 {
    padding-left: 8px;
}

.pdl10 {
    padding-left: 10px;
}

.pdl12 {
    padding-left: 12px;
}

.pdl15 {
    padding-left: 15px;
}

.pdl20 {
    padding-left: 20px !important;
}

.pdl24 {
    padding-left: 24px;
}

.pdl25 {
    padding-left: 25px;
}

.pdl30 {
    padding-left: 30px;
}

.pdl35 {
    padding-left: 35px;
}

.pdl40 {
    padding-left: 40px;
}

.pdl45 {
    padding-left: 45px;
}

.pdl50 {
    padding-left: 50px;
}

.pdl55 {
    padding-left: 55px;
}

.pdl65 {
    padding-left: 65px;
}

.pdl60 {
    padding-left: 60px;
}

.pdl70 {
    padding-left: 70px;
}

.pdl80 {
    padding-left: 80px;
}

.pdl95 {
    padding-left: 95px;
}

.pdl100 {
    padding-left: 100px;
}

.pdt160 {
    padding-top: 160px;
}

.pdt140 {
    padding-top: 140px;
}

.pdt120 {
    padding-top: 120px;
}

.pdt80 {
    padding-top: 80px;
}

.pdt70 {
    padding-top: 70px;
}

.pdt60 {
    padding-top: 60px;
}

.pdt50 {
    padding-top: 50px;
}

.pdt40 {
    padding-top: 40px;
}

.pdt35 {
    padding-top: 35px;
}

.pdt30 {
    padding-top: 30px;
}

.pdt25 {
    padding-top: 25px;
}

.pdt24 {
    padding-top: 24px;
}

.pdt20 {
    padding-top: 20px;
}

.pdt16 {
    padding-top: 16px;
}

.pdt15 {
    padding-top: 15px;
}

.pdt10 {
    padding-top: 10px;
}

.pdt8 {
    padding-top: 8px;
}

.pdt7 {
    padding-top: 7px;
}

.pdt5 {
    padding-top: 5px;
}

.pdt3 {
    padding-top: 3px;
}

.pdt2 {
    padding-top: 2px;
}

.pdt4 {
    padding-top: 4px;
}

.pdt0 {
    padding-top: 0;
}

.pdr0 {
    padding-right: 0 !important;
}

.pdr3 {
    padding-right: 3px;
}

.pdr5 {
    padding-right: 5px;
}

.pdr8 {
    padding-right: 8px;
}

.pdr10 {
    padding-right: 10px;
}

.pdr15 {
    padding-right: 15px;
}

.pdr20 {
    padding-right: 20px;
}

.pdr24 {
    padding-right: 24px;
}

.pdr25 {
    padding-right: 25px;
}

.pdr30 {
    padding-right: 30px;
}

.pdr40 {
    padding-right: 40px;
}

.pdr45 {
    padding-right: 45px;
}

.pdr50 {
    padding-right: 50px;
}

.pdr60 {
    padding-right: 60px;
}

.pdr80 {
    padding-right: 80px;
}

.pdr100 {
    padding-right: 100px;
}

.pdbt200 {
    padding-bottom: 100px;
}

.pdbt105 {
    padding-bottom: 105px;
}

.pdbt70 {
    padding-bottom: 70px;
}

.pdbt60 {
    padding-bottom: 60px;
}

.pdbt50 {
    padding-bottom: 50px;
}

.pdbt40 {
    padding-bottom: 40px;
}

.pdbt35 {
    padding-bottom: 35px;
}

.pdbt30 {
    padding-bottom: 30px;
}

.pdbt25 {
    padding-bottom: 25px;
}

.pdbt24 {
    padding-bottom: 24px;
}

.pdbt20 {
    padding-bottom: 20px;
}

.pdbt16 {
    padding-bottom: 16px;
}

.pdbt15 {
    padding-bottom: 15px;
}

.pdbt10 {
    padding-bottom: 10px;
}

.pdbt8 {
    padding-bottom: 8px;
}

.pdbt5 {
    padding-bottom: 5px;
}

.pdbt4 {
    padding-bottom: 4px;
}

.pdbt3 {
    padding-bottom: 3px;
}

.pdbt0 {
    padding-bottom: 0px;
}

//Margin

.mgau {
    margin: auto;
}

.mg15 {
    margin: 15px;
}

.mg20 {
    margin: 20px;
}

.mg25 {
    margin: 25px;
}

.mgbt90 {
    margin-bottom: 90px;
}

.mgbt80 {
    margin-bottom: 80px;
}

.mgbt70 {
    margin-bottom: 70px;
}

.mgbt65 {
    margin-bottom: 65px;
}

.mgbt60 {
    margin-bottom: 60px;
}

.mgbt50 {
    margin-bottom: 50px;
}

.mgbt55 {
    margin-bottom: 55px;
}

.mgbt45 {
    margin-bottom: 45px;
}

.mgbt40 {
    margin-bottom: 40px;
}

.mgbt35 {
    margin-bottom: 35px;
}

.mgbt30 {
    margin-bottom: 30px;
}

.mgbt25 {
    margin-bottom: 25px;
}

.mgbt24 {
    margin-bottom: 24px;
}

.mgbt20 {
    margin-bottom: 20px;
}

.mgbt15 {
    margin-bottom: 15px;
}

.mgbt16 {
    margin-bottom: 16px;
}

.mgbt12 {
    margin-bottom: 12px;
}

.mgbt10 {
    margin-bottom: 10px !important;
}

.mgbt8 {
    margin-bottom: 8px;
}

.mgbt5 {
    margin-bottom: 5px;
}

.mgbt3 {
    margin-bottom: 3px;
}

.mgbt0 {
    margin-bottom: 0 !important;
}

.mgl80 {
    margin-left: 80px;
}

.mgl60 {
    margin-left: 60px;
}

.mgl40 {
    margin-left: 40px;
}

.mgl30 {
    margin-left: 30px;
}

.mgl25 {
    margin-left: 25px;
}

.mgl20 {
    margin-left: 20px;
}

.mgl15 {
    margin-left: 15px;
}

.mgl16 {
    margin-left: 16px;
}

.mgl10 {
    margin-left: 10px;
}

.mgl9 {
    margin-left: 9px;
}

.mgl50 {
    margin-left: 50px;
}

.mgl8 {
    margin-left: 8px;
}

.mgl5 {
    margin-left: 5px;
}

.mgl0 {
    margin-left: 0;
}

.mgt5-minus {
    margin-top: -5px;
}

.mgt0 {
    margin-top: 0;
}

.mgt1 {
    margin-top: 1px;
}

.mgt4 {
    margin-top: 4px;
}

.mgt5 {
    margin-top: 5px;
}

.mgt6 {
    margin-top: 6px;
}

.mgt7 {
    margin-top: 7px;
}

.mgt8 {
    margin-top: 8px;
}

.mgt9 {
    margin-top: 9px;
}

.mgt10 {
    margin-top: 10px;
}

.mgt12 {
    margin-top: 12px;
}

.mgt15 {
    margin-top: 15px;
}

.mgt16 {
    margin-top: 16px;
}

.mgt18 {
    margin-top: 18px;
}

.mgt20 {
    margin-top: 20px;
}

.mgt25 {
    margin-top: 25px;
}

.mgt24 {
    margin-top: 24px;
}

.mgt26 {
    margin-top: 26px;
}

.mgt30 {
    margin-top: 30px;
}

.mgt32 {
    margin-top: 32px;
}

.mgt35 {
    margin-top: 35px;
}

.mgt39 {
    margin-top: 39px;
}

.mgt40 {
    margin-top: 40px;
}

.mgt45 {
    margin-top: 45px;
}

.mgt50 {
    margin-top: 50px;
}

.mgt53 {
    margin-top: 53px;
}

.mgt60 {
    margin-top: 60px;
}

.mgt70 {
    margin-top: 70px;
}

.mgr5 {
    margin-right: 5px;
}

.mgr10 {
    margin-right: 10px;
}

.mgr8 {
    margin-right: 8px;
}

.mgr15 {
    margin-right: 15px;
}

.mgr20 {
    margin-right: 20px;
}

.mgr25 {
    margin-right: 25px;
}

.mgr30 {
    margin-right: 30px;
}

.mgr35 {
    margin-right: 35px;
}

.mgr40 {
    margin-right: 40px;
}

.mgr45 {
    margin-right: 45px;
}

.mgr50 {
    margin-right: 50px;
}

.mgr55 {
    margin-right: 55px;
}

.mgr85 {
    margin-right: 85px;
}

.mgr-10 {
    margin-right: -10px;
}

//Line height
.line-height134 {
    line-height: 1.34;
}

.line-height167 {
    line-height: 1.67;
}

//Border-color
.border-bottom-0x {
    border-bottom: 0 !important;
}

.border-bottom-1x {
    border-bottom: 1px solid;
}

.border-bottom-1x-grey {
    border-bottom: 1px solid #e8e8e8;
}

.border-bottom-2x {
    border-bottom: 2px solid;
}

.border-top-1x {
    border-top: 1px solid;
}

.border-top-1x-grey {
    border-bottom: 1px solid #e8e8e8;
}

.border-top-2x {
    border-top: 2px solid;
}

.border-right-1x {
    border-right: 1px solid;
}

.border-left-1x {
    border-left: 1px solid;
}

.bd0 {
    border: 0px !important;
}

.bd1px {
    border: 1px solid;
}

.borderdotted-bootom-2x {
    border-bottom: 2px dotted;
}

.bdbt0 {
    border-bottom: 0;
}

.bdt0 {
    border-top: 0;
}

.border-top-3x {
    border-top: 3px solid;
}

.border-tb-dashed {
    border-top: 0.1px dashed $bg-color-blue !important;
    border-bottom: 0.1px dashed $bg-color-blue !important;
}

.bd-color-black {
    border-color: #2f3439;
}

.bd-color-gray {
    border-color: rgba(0, 0, 0, 0.14);
}

.bd-color-gray2 {
    border-color: rgba(0, 0, 0, 0.25);
}

.bd-color-gray4 {
    border-color: #ebe5e5;
}

.bd-color-blue {
    border-color: #1890ff;
}

.bd-color-green {
    border-color: #7cb305 !important;
}

.bd-color-red {
    border-color: red;
}

.bd-color-white {
    border-color: white;
}

.bd-color-mix-blue {
    border-color: #4673c1;
}

//Position
.position-re {
    position: relative;
}

.position-ab {
    position: absolute;
}

.position-fix {
    position: fixed;
}

//z-index
.zindex0 {
    z-index: 0;
}

.zindex9 {
    z-index: 9;
}

.zindex99 {
    z-index: 99;
}

.zindex999 {
    z-index: 999;
}

.zindex9999 {
    z-index: 9999;
}

//Float
.fll {
    float: left;
}

.flr {
    float: right;
}

.clearboth {
    clear: both;
}

// Transformation
.txt-uppercase {
    text-transform: uppercase;
}

.txt-lowercase {
    text-transform: lowercase;
}

.txt-capitalize {
    text-transform: capitalize !important;
}

.txt-transform-none {
    text-transform: none !important;
}

//Decoration
.txt-underline {
    text-decoration: underline;
}

.txt-linethrough {
    text-decoration: line-through;
}

.txt-overline {
    text-decoration: overline;
}

// Alignment
.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right !important;
}

.txt-center {
    text-align: center;
}

.txt-justify {
    text-align: justify;
}

.txt-nowrap {
    white-space: nowrap;
}

.txt-initial {
    white-space: initial;
}

//Display
.dpl-none {
    display: none;
}

.dpl-block {
    display: block !important;
}

.dpl-il-block {
    display: inline-block;
}

.dpl-flex {
    display: flex;
}

.dpl-none-mobile {
    display: block;
}

.dpl-none-pc {
    display: none;
}

//Flex
.justify-content-between {
    justify-content: space-between;
}

.justify-content-start {
    justify-content: start;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.alignitemscenter {
    align-items: center;
}

.alignitemsend {
    align-items: flex-end;
}

//Overflow
.overflow-hidden {
    overflow: hidden;
}

.overflowy-hidden {
    overflow-y: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflowXscroll {
    overflow-x: scroll;
}

.overflowYscroll {
    overflow-y: scroll;
}

.wordbreakall {
    word-break: break-all;
}

.wordbreakword {
    word-break: break-word;
}

.cursor-pointer {
    cursor: pointer;
}

.text-underline {
    text-decoration: underline;
}

.cursor-nodrop {
    cursor: no-drop;
}

.whitespace {
    white-space: nowrap;
}

.whitespace-initial {
    white-space: initial !important;
}

.whitespace-inherit {
    white-space: inherit !important;
}

.opacity6 {
    opacity: 0.6;
}

.bottom0 {
    bottom: 0;
}

//Border-radius
.border-radius2 {
    @include border-radius(2px);
}

.border-radius4 {
    @include border-radius(4px);
}

.border-radius6 {
    @include border-radius(6px);
}

.bdr0 {
    border-right: 0;
}

.width30 {
    width: 30%;
}

.width70 {
    width: 70%;
}

.width80 {
    width: 80%;
}

.width100px {
    width: 100px;
}

.width250px {
    width: 250px;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.imgthumb32 {
    width: 32px;
    height: 32px;
}

.imgthumb14 {
    width: 14px;
    height: 14px;
}

.imgthumb120 {
    width: 120px;
    height: 120px;
}

.ant-avatar {
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.width100 {
    width: 100%;
}

.width50 {
    width: 50%;
}

.width25 {
    width: 25%;
}

.min-width-135 {
    min-width: 135px;
}

.min-width-150 {
    min-width: 150px;
}

.min-width-200 {
    min-width: 200px;
}

.height100 {
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-initial {
    cursor: initial;
}

.shadow-bottom {
    @include box-shadow(0, 3px, 5px, 0, rgba(0, 21, 41, 0.05));
}

.ant-avatar {
    width: 24px;
    height: 24px;
    @include border-radius(4px);
}

.ant-layout-sider-collapsed {
    //flex: 0 0 60px !important;
    //max-width: 60px !important;
    //min-width: 60px !important;
    //width: 60px !important;
}

/***************************
		FORM
***************************/
.ant-form-item {
    margin-bottom: 16px;
}

.ant-select-selector,
.ant-picker {
    min-height: 32px !important;
    //line-height: 32px;
    border: 1px solid #c7c7c7 !important;
    @include border-radius(8px !important);

    &:hover {
        border: 1px solid $bg-color-blue !important;
    }

    &:focus {
        border: 1px solid $bg-color-blue !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
        outline: 0 none;
    }

    .ant-select-selection-placeholder {
        color: #333333;
        font-size: $txt-size-h7;
    }
}

.ant-picker {
    width: 100%;
}

input[type='text'],
input[type='email'],
input[type='password'],
select,
textarea {
    height: 30px;
    font-size: 14px !important;
    color: #707070;
    @apply rounded-lg;
}

.wplabel {
    label {
        font-size: $txt-size-h7;
        color: $txt-color-blue;
    }

    .ant-checkbox-wrapper {
        color: $txt-color-black;
    }
}

.ant-checkbox {
    //width: 12px;
    //height: 12px;
    margin-right: 1px;
}

.ant-picker-range-separator {
    line-height: 0;
}

.ant-select-selector {
    font-size: 14px;
    color: #707070;
}

/***************************
		TAB
***************************/
.tab-title {
    .tab-title-pc {
        display: block;
        @media only screen and (max-width: 767px) {
            display: none;
        }

        .title {
            color: $txt-color-black3;
            font-size: 14px;
            line-height: 22px;
            text-shadow: none;
        }
    }

    .tab-title-mb {
        display: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }

        .title--mobile {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .total {
        //color: $txt-color-blue;
        background-color: #ddefef;
        border-radius: 20px;
        padding: 1px 8px;
        margin: 0 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
    }

    :hover {
        color: $txt-color-blue;
    }
}

@media (max-width: 767px) {
    // .left-header .title {
    //     display: none;
    // }
    .list-data {
        margin-top: 16px;
    }
}

@media (max-width: 767px) {
    .m3-dropdown {
        width: 350px !important;
    }
    // .main-layout {
    //     .main-content {
    //         padding-left: 16px;
    //         padding-right: 16px;
    //     }
    // }
}

a {
    color: $txt-color-blue;
}

.box {
    background: #ffffff;
    /* BG Shadow */

    box-shadow: 0px 2px 14px #e6e6e6;
    border-radius: 6px;
    padding: 12px;

    @include gt-md {
        padding: 16px;
    }

    .box-title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px;

        .total {
            background-color: #ddefef;
            border-radius: 20px;
            padding: 1px 8px;
            margin: 0 4px;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
        }
    }
}

.box-top {
    background: #ffffff;
    /* BG Shadow */

    box-shadow: 0 2px 14px #e6e6e6;
    border-radius: 0 0 6px 6px;

    .box-title {
        font-weight: 600;
        font-size: 16px;
        color: #007574;

        .total {
            background-color: #ddefef;
            border-radius: 20px;
            padding: 1px 8px;
            margin: 0 4px;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
        }
    }
}

.text-normal {
    font-weight: 500;
    font-size: 14px;
}

.mobile {
    display: none;
    @media only screen and (max-width: 767px) {
        display: block;
    }
}

.ticket-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 62px;
    z-index: 9;
    box-shadow: 0 2px 14px #e6e6e6;
    @media only screen and (max-width: 767px) {
        left: 0;
        padding: 10px;
    }
}

.border-0 {
    border: 0;
}

.border-isMobile-0 {
    @media only screen and (min-width: 768px) {
        border-top: 0 !important;
    }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .ant-layout-sider {
        //height: 100%;
        //overflow: auto;
    }
    .main-layout .sidebar-v2 .ant-menu-item {
        //height: auto !important;
    }
}

.text-mb-120 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    //width: 220px;
    max-width: 500px;
    white-space: nowrap;
    @media (min-width: 1025px) and (max-width: 1366px) {
        max-width: 160px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 140px;
    }
    @media (max-width: 767px) {
        max-width: 120px;
    }
}
