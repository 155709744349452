.ant-drawer-header {
    padding: 16px;
    border-bottom: 1px solid transparent;
    &-title {
        .ant-drawer-title {
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
        }
        .ant-drawer-close {
            position: absolute;
            right: 0;
        }
    }
}

.ant-drawer-body {
    padding: 8px 16px;
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
}




.ant-rate-disabled {
    .anticon svg {
        display: inline-block;
        width: 15px;
        height: 15px;
    }
}

.ant-empty {
    margin: 24px 8px;
}