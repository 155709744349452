.main-body {
    height: 100vh;
    background-color: #e5e5e5;
}

$md-menu-width: 80px;

.main-layout {
    .header-v2 {
        background-color: #fff;
        flex-direction: row;
        justify-content: space-between;
        display: flex;
        z-index: 99;
        -moz-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.2);
        -webkit-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.2);
        box-shadow: 0 2px 4px rgba(57, 63, 72, 0.2);
        position: fixed;
        padding: 0 12px;
        width: 100vw;

        @include gt-lg {
            padding: 0 16px;
            margin-left: $md-menu-width;
            width: calc(100vw - $md-menu-width);
        }

        .left-header {
            .expandAction {
                display: inline-block;

                @include gt-lg {
                    display: none;
                }
            }

            .select-box {
                min-width: 200px;
            }

            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;

            .title {
                line-height: 20px;
                display: none;

                @include gt-md {
                    display: inline;
                }

                @media only screen and (min-width: 768px) and (max-width: 888px) {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .mobile-header-title {
            display: none;

            @media only screen and (max-width: 768px) {
                display: block;
                color: #ffffff;
            }
        }

        .right-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            // margin-right: 25px;

            .user-info {
                position: relative;
                margin-left: 16px;
                z-index: 9999;

                .language-box {
                    padding: 2px 10px;

                    p {
                        padding: 10px 0;
                        line-height: 8px;

                        a {
                            color: #333333;

                            &:hover {
                                //color: $color--primary;
                            }
                        }
                    }

                    p:nth-child(2n + 0) {
                        padding-top: 15px;
                        border-top: 1px solid #eeeeee;
                    }
                }

                .pas-pin {
                    padding: 2px 10px;

                    p {
                        padding: 10px 0;
                        line-height: 8px;

                        a {
                            color: #333333;

                            &:hover {
                                //color: $color--primary;
                            }
                        }
                    }

                    p:nth-child(2n + 0) {
                        padding-top: 15px;
                        border-top: 1px solid #eeeeee;
                    }
                }

                .tenant-box {
                    padding: 2px 16px;

                    p {
                        padding: 15px 0;
                        line-height: 1.34;
                        margin-bottom: 0;

                        &+p {
                            border-top: 1px solid #eeeeee;
                        }

                        a {
                            color: #333333;

                            &:hover {
                                //color: $color--primary;
                            }
                        }
                    }
                }
            }

            .account {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 20px;
                align-content: center;

                p {
                    margin-right: 10px;
                    align-self: center;
                }

                span {
                    align-self: center;
                    font-size: 12px;
                }
            }
        }

        .user-info-mobile {
            display: none;
        }

        @media (max-width: 767px) {
            min-width: 100%;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;

            .user-info-web {
                display: none;
            }

            .menu {
                display: inline-block;
                margin-left: 10px;
            }

            .user-info-mobile {
                display: inline-block;
            }

            .button {
                display: none !important;
            }
        }
    }

    .ant-layout-sider {
        @include gt-lg {
            width: $md-menu-width !important;
            min-width: $md-menu-width !important;
            box-shadow: 4px 0px 8px 0px rgba(17, 17, 17, 0.15);
        }
    }

    .main-content {  

        @include gt-lg {
            padding-left: 77px;
            padding-top: 0;
            padding-right: 0;
            margin-top: 64px;
            min-height: calc(100vh - 64px - 18px);
            overflow: auto;
        }



        .content-container {
            width: 100%;
            background-color: #edf3f7;
            padding: 12px 14px 14px 14px;
            padding-bottom: 32px;
            position: absolute;
            top: 65px;

            @include gt-lg {
                position: absolute;
                top: 65px;
                width: calc(100vw - 80px);
            }
        }

        @media screen and (max-width: 768px) {
            padding-left: 0;
        }

        .bread-crumb-container {
            background-color: #fff;
            padding: 15px 30px 12px;
        }

        .white-box {
            background-color: #fff;
            margin: 15px;
            border-radius: 4px;

            &--padding-15px {
                padding: 15px;
            }

            &.no-margin {
                margin: 0;
            }
        }

        .tab-pane-inner-box {
            padding: 0 20px 20px 20px;
        }

        .h-line {
            border-bottom: 1px solid #ccc;
        }

        .loading-container {
            text-align: center;
            padding: 30px;
        }
    }
}

// detail page
.detail-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 49px;
    gap: 16px;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }

    .mobile-comment {
        display: none;

        @media only screen and (max-width: 767px) {
            display: block;

            .icon-list {
                position: fixed;
                bottom: 56px;
                left: 12px;
                z-index: 100;
                display: flex;
                flex-direction: column;

                .icon {
                    cursor: pointer;
                    margin-top: 20px;
                    width: 56px;
                    height: 56px;
                    border-radius: 28px;
                    background-color: #008df9;
                    color: #ffffff;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    display: flex;
                    font-size: 20px;
                }
            }
        }
    }

    $comment-width: 350px;

    .information {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: auto;
        width: 100%;

        @include gt-sm {
            width: calc(100% - ($comment-width + 8px));
        }
    }

    .comments {
        position: fixed;
        right: 8px;
        top: calc(64px + 16px);
        flex-direction: column;
        gap: 16px;
        width: $comment-width;
        height: calc(100vh - (64px + 32px + 40px));
        display: none;

        * {
            transition: none !important;
        }

        @include gt-sm {
            display: flex;
        }
    }
}