.custom-menu {
    // width: fit-content;
    border: none !important;

    .ant-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100% !important;
        padding: 10px 6px !important;
        color: #ffffff;
        background-color: #007574;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        .ant-menu-item-icon {
            padding: 8px 12px;
            border-radius: 20px;
            min-width: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
        }

        &:hover .ant-menu-item-icon {
            background-color: #cce3e3;
        }

        .ant-menu-title-content {
            margin: 0;
            padding-top: 6px;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            //white-space: break-spaces;
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }

        &:focus-visible {
            box-shadow: none;
            border: 1px solid transparent;
        }
    }

    .ant-menu-item:hover {
        .ant-menu-title-content {
            color: #ffffff !important;
        }
    }

    .ant-menu-item-selected {
        background: #007574 !important;

        .ant-menu-title-content {
            font-weight: 500;
            color: #ffffff;
        }

        .ant-menu-item-icon {
            color: #1a1a1a;
            background-color: #cce3e3;
        }
    }

    .ant-menu-submenu {
        .ant-menu-submenu-title {
            @extend .ant-menu-item;
        }

        .ant-menu-submenu-arrow {
            display: none;
        }
    }

    .ant-menu-submenu-selected {
        .ant-menu-item-icon {
            color: #007574;
            background-color: #cce3e3;
        }
    }
}

.ant-menu-sub {
    max-height: 100vh !important;
    height: 100vh;
    min-width: 250px !important;
    padding: 12px 12px 12px 32px!important;

    .ant-menu-item-group-title {
        padding: 8px 12px;
        font-weight: 500;
        font-size: 16px;
        // color: #001212;
        color: #ffff;
    }
    

    .ant-menu-item-group-list {
        .ant-menu-item {
            padding: 0 12px 6px 12px;
            border-radius: 50px;

            .ant-menu-title-content {
                display: flex;
                justify-content: space-between;
                color: #ffff;

                .item-badge {
                    //height: 32px;
                    //padding: 0 6px;
                    //margin: 2px 0;
                    //background: #FFFFFF;
                    //border-radius: 20px;
                    // color: #007574;
                    color: #ffff;
                    font-weight: 500;
                }
            }

            &:hover {
                // background: #f1f1f1;
                background: #078C8B;
                font-weight: 500;
            }

            &:focus-visible {
                box-shadow: none;
                border: 1px solid transparent;
            }
        }

        .ant-menu-item-selected {
            // background: #dbf7eb !important;
            background-color: #116160 !important;
            // color: #1a1a1a;
            color: #ffff;
            border: 1px solid transparent;
            font-weight: 500;
        }
    }
}

.ant-menu-submenu-popup {
    position: fixed !important;
    top: 0 !important;
    left: 65px !important;
    z-index: 999;
    border-radius: 0 4px 4px 0 !important;
    box-shadow: none;
    transform-origin: 0 0;
    height: 100vh;
    max-width: 25%;
    min-width: 20%;
}

.new-side-bar {
    height: 100vh;
    z-index: 1000;
    box-shadow: 6px 0 16px rgba(112, 112, 112, 0.1);
    border-radius: 0 8px 8px 0;
    position: fixed !important;
    top: 0;
    left: 0;
    transition: width 0.5s ease;
    overflow-x: hidden;
    overflow-y: hidden;
    background: #007574 !important;
    width: 0;
    min-width: 0;

    @include gt-md {
        width: 96px !important;
    }

    .nav-bar {
        .logos {
            display: flex;
            // flex-direction: column;
        }
    }
}

.new-side-bar_expand {
    width: 96px !important;
    min-width: 0 !important;
    transition: width 0.5s ease;
}

.new-side-bar_collapsed {
    width: 0 !important;
    min-width: 0 !important;
    transition: width 0.5s ease;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
    background: #007574;
}