.ant-tabs {
    .ant-tabs-tab {
        text-transform: capitalize;
    }

    &.ant-tabs-left {
        > .ant-tabs-nav {
            width: 202px;
            height: fit-content;
            padding: 0px;
            background-color: unset;
            padding-top: 32px;

            .ant-tabs-nav-list {
                flex: initial;

                .ant-tabs-tab {
                    height: 48px;
                    border-radius: 6px 0px 0 6px;
                    background-color: #fff;

                    &.ant-tabs-tab-active {
                        border: none;
                        background: $primary-color-400;
                        * {
                            color: #fff;
                        }
                    }

                    .ant-tabs-tab-btn {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }

                .ant-tabs-ink-bar {
                    display: none;
                }
            }
        }

        > .ant-tabs-content-holder {
            > .ant-tabs-content {
                .ant-tabs-tabpane {
                    padding: 16px;
                    background-color: #fff;
                    border-radius: 6px;
                    box-shadow: 0px 0px 6px rgb(0 0 0 / 5%), 0px 7px 24px rgb(17 17 17 / 8%);
                }
            }
        }

        &.tab-in-tab {
            > .ant-tabs-content-holder {
                background: transparent;
                box-shadow: none;

                > .ant-tabs-content {
                    .ant-tabs-tabpane:has(.ant-tabs-top) {
                        background-color: transparent;
                        padding: 0;
                        box-shadow: none;

                        .ant-tabs.ant-tabs-top.ant-tabs-card {
                            box-shadow: none;
                            background: transparent;

                            .ant-tabs-nav {
                                padding: 0;
                            }
                            .ant-tabs-content-holder {
                                .ant-tabs-tabpane {
                                    background: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.ant-tabs-top {
        .ant-tabs-tab {
            font-weight: 500;
        }

        &:not(.ant-tabs-card) {
            .ant-tabs-tab {
                color: $text-disable-color;

                .bag {
                    background-color: $grey-scale;
                    color: $text-disable-color;
                    border-radius: 20px;
                    padding: 0 8px;
                    margin-left: 6px;
                    display: inline-block;
                    height: 16px;
                    line-height: 16px;
                    font-size: 12px;
                    font-size: 12px;
                }

                &.ant-tabs-tab-active {
                    .bag {
                        background-color: $primary-color-400;
                        color: #fff;
                    }
                }
            }
        }

        &.ant-tabs-card {
            box-shadow: 0px 0px 6px rgb(0 0 0 / 5%), 0px 7px 24px rgb(17 17 17 / 8%);
            border-radius: 6px;
            background-color: #fff;

            > .ant-tabs-nav {
                padding: 0 16px;
                height: 32px;
                margin: 0;
                border-radius: 6px 6px 0 0;

                &::before {
                    left: 16px;
                }

                .ant-tabs-nav-list {
                    gap: 4px;

                    .ant-tabs-tab {
                        border: none;
                        background-color: $grey-scale;
                        color: $secondary-color;
                        border-radius: 6px 6px 0 0;

                        &.ant-tabs-tab-active {
                            background-color: $primary-color-400;
                            * {
                                color: #fff;
                            }
                        }
                    }
                }
            }

            &.tabs-secondary {
                .ant-tabs-tab + .ant-tabs-tab {
                    margin: 0;
                }
            }
        }
    }
}

.ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important;
}
