.break-word {
    word-break: break-word;
}

.btnLink:hover{
    text-decoration: underline;
}

.pointer-events-none {
    pointer-events: none;
}

.ip-warning {
    color: #ffc107;
    font-size: 14px;
    line-height: 1.34;
    margin-top: 5px;
    padding-left: 10px;
    display: block;
    font-family: "Roboto", sans-serif;
}

.tooltip-custom {
    background-color: #fff!important;
    color: #000!important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(153,153,153,0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(153,153,153,0.5);
    box-shadow: 0px 0px 5px 0px rgba(153,153,153,0.5);
    opacity: 1!important;
    font-size: 11px;
    max-width: 265px!important;
    padding-left: 15px!important;
    padding-right: 15px!important;
}

.tooltip-custom:after {
    border-bottom-color: #fff !important;
    border-bottom-style: solid !important;
    border-bottom-width: 6px !important;
}

.tooltip-custom:before {
    border-bottom-color: #dfe1e4 !important;
    border-bottom-style: solid !important;
    border-bottom-width: 8px !important;
}

.tooltip-custom.top:after {
    border-top-color: #fff !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
    border-bottom-color: unset !important;
    border-bottom-style: unset !important;
    border-bottom-width: unset !important;
}

.tooltip-custom.top:before {
    border-top-color: #dfe1e4 !important;
    border-top-style: solid !important;
    border-top-width: 8px !important;
    border-bottom-color: unset !important;
    border-bottom-style: unset !important;
    border-bottom-width: unset !important;
}

.z-index-hidden {
    z-index: -1;
}

.break-word p {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.show-ipad {
    display: none;
}

.pdr32 {
    padding-right: 32px!important;
}

.line-height-30 {
    line-height: 30px
}

.height-30 {
    height: 30px;
}

.border-radius-5 {
    border-radius: 5px;
}

.ellipsis-1 {
    overflow: hidden!important;
    max-height: 18px;
}

.ellipsis-2 {
    overflow: hidden!important;
    max-height: 36px;
}

.ellipsis-3 {
    overflow: hidden!important;
    max-height: 54px;
}

.ellipsis-3-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.dpl-il-flex {
    display: inline-flex;
}

.cursor-initial,.cursor-initial .cursor-pointer {
    cursor: initial!important;
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.no-margin {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.no-vertical-scroll {
    overflow-y: hidden!important;
}

.width90 {
    width: 90%;
}

.width100 {
    width: 100%;
}

.max-width-40 {
    max-width: 40%;
}

.max-width-50 {
    max-width: 50%;
}

.max-width-90 {
    max-width: 90%;
}

.max-width-100 {
    max-width: 100%;
}

.max-width-200 {
    max-width: 200px;
}

.min-width-140 {
    min-width:140px
}

.whitespace-pre-wrap {
    white-space: pre-wrap;
}

.flex-none {
    flex: none;
}

.splash-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropzone {
    display: flex;
    flex-wrap: wrap;
}

.dropzone > div:not(.dropped-file) {
    position: relative;
    width: 70px!important;
    height: 70px!important;
    border: 1px dashed #dfe1e4!important;
    border-radius: 5px;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dropzone > div:not(.dropped-file).ibizan-focus {
    border: 1px dashed #fc7056!important;
}


.dropzone > div:not(.dropped-file) > i {
    color: #dfe1e4;
}

.dropped-files {
    display: inline-block;
    padding: 0;
    margin: 0;
    vertical-align: middle;
}

.dropped-file {
    display: inline-block;
    list-style: none;
    border-radius: 5px;
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    background-position: center;
    background-size: cover;
    position: relative;
}

.remove-image-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 50%;
    background-color: #cccccc;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dropped-file:hover .remove-image-btn {
    display: flex;
}
.remove-image-btn i {
    font-size: 15px;
    color: #fff;
}

.remove-image-btn:hover {
    background-color: #e91e63
}

.camera-dropzone>div {
    border: none!important;
    width: unset!important;
    height: unset!important;
}

.select-control {
    display: inline-block;
}

.select-control span.title {
    display: inline-block;
    height: 33px;
    line-height: 33px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 14px;
    color: #000000;
    font-family: "UTMhelve-regular", sans-serif;
    background-color: #fff;
    padding-right: 22px;
    white-space: nowrap;
    overflow: hidden;
}

.select-control:hover span.title {
    border: 1px solid #008080;
}

.select-control>div {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 110%;
    min-width: 100%;
    font-size: 14px;
}

.popup-container {
    box-shadow: 0 0 5px 1px #edeff2;
    background: #fff;
    border-radius: 4px;
}

.popup-search-input {
    padding: 10px
}

.select-control .ic-down {
    top: 2px;
    right: 5px;
    width: 18px;
    line-height: 27px;
    height: 29px;
    background: #fff;
    border-radius: 4px;
}

.ul-select-list li {
    padding: 10px;
}

.ul-select-list li:hover {
    background-color: #f5f5f5;
    color: #008080;
}

.fake-input {
    display: inline-block;
    height: 33px;
    line-height: 30px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 14px;
    color: #000000;
    font-family: "UTMhelve-regular", sans-serif;
    background-color: #fff;
    padding-right: 22px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.fake-input i {
    position: absolute;
    right: 5px;
    line-height: 32px;
    font-size: 20px;
}

.daterangepicker .drp-buttons .btn {
    padding: 0 23px;
}

.mgl5 {
    margin-left: 5px;
}

.input-range-container {
    display: flex;
}

.input-range-box {
    display: flex;
}

.page-item .page-link {
    color: #666666;
    margin-left: 0;
}

.page-item.disabled {
    opacity: 0.3;
    cursor: no-drop;
}

.page-item.active .page-link {
    background-color: #fff;
    color: #008080;
}

.header__breadcrumb ul > li > a {
    color: #008080!important;
}

/*.react-datepicker{*/
    /*height: 330px;*/
    /*display: flex;*/
/*}*/

.react-datepicker__time-container{
    overflow-y: hidden;
    overflow-x: hidden;
}

.react-datepicker__header--time{
    padding-top: 7px;
}
.react-datepicker-popper {
    z-index: 99;
    top: 100%!important;
    left: 0!important;
    bottom: unset!important;
    right: unset!important;
    transform: unset!important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    width: 70px
}

.calendar-right .react-datepicker-popper {
    left: unset!important;
    right: 0!important;
}

.calendar-right .react-datepicker__triangle {
    left: unset!important;
    right: 50px!important;
}

/*.date-picker-container {*/
    /*position: relative;*/
/*}*/

/*.date-picker-container i.icon-calendar {*/
    /*position: absolute;*/
    /*top: 3px;*/
    /*right: 5px;*/
    /*line-height: 30px;*/
    /*font-size: 18px;*/
/*}*/

.list-assignee-icon .assignee-icon-container {
    margin-left: 2px
}

.list-assignee-icon .assignee-icon-container:first-child {
    margin-left: 0;
}

.assignee-icon {
    display: inline-block;
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 50%;
    cursor: default;
}

.assignee-icon span {
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #607d8b;
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding: 3px 0 7px 0;
}

.assignee-icon img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #dfe1e4
}

.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 5px;
    margin-bottom: 5px;
    background: #fefefe;
    line-height: 1.34;
}
.editor .rdw-link-modal {
    height: auto;
}
.editor #openLinkInNewWindow {
    height: auto;
}
.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}
.DraftEditor-editorContainer {
    position: relative;
    overflow-y: auto;
    height: 200px;
}

.show-mobile {
    display: none;
}

.dropdown__title {
    padding-right: 25px;
}

.dropdown__title div {
    white-space: nowrap;
    overflow: hidden;
}

.pagination__wpicon {
    line-height: 30px;
}

.tabsibizanmobile {
    display: none;
    margin-bottom: 15px;
}

.ibizan-focus {
    border-color: #fc7056;
}

.btn--green3:focus {
    background: #008080;
    color: #ffffff;
}

.textareanote p {
    padding-bottom: 10px;
}

.item-icon {
    display: flex;
    height: 31px;
    align-items: center;
    position: unset!important;
}
.item-icon img {
    height: 18px;
    width: auto;
}
.item-icon span {
    padding-left: 10px;
    position: unset;
    line-height: 18px;
    font-size: 14px;
}

.overlay-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
}

.ul-select-list li.selected {
    background-color: #f5f5f5;
    color: #008080;
}
.ellipsis-text,
.ellipsis-html {
    margin: 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f2f7;
}

.ellipsis-text {
    white-space: pre-wrap;
}

.LinesEllipsis{
    white-space: pre-wrap;
}
.LinesEllipsis--clamped {
    cursor: pointer;
}

.LinesEllipsis-ellipsis {
    color: #2196f3;
}

.LinesEllipsis--clamped:hover .LinesEllipsis-ellipsis {
    color: #3e7ac2;
}
.ibizan-input-hidden{
    width:0;
    height:0;
    border:0;
    display: block;
    padding: 0;
    background-color: transparent;
}

.table-solution .td-solution {
    width: 40%;
    max-width: 250px;
}

.table-solution .td-description {
    width: 50%;
    max-width: 350px;
}

.btn {
    padding: 0 10px;
}

.btn.btn-icon {
    padding-right: 20px;
}

.iptext-error {
    border: 1px solid #e91e63!important;
}

.ip-error {
    color: #e91e63;
}

.width240 {
    width: 240px
}

.popup-form .widthinput {
    width: 385px;
}

.popup-form {
    position: relative;
    padding: 15px;
    height: unset!important;
}

.popupbox__close span {
    line-height: 22px;
}

.fake-modal-boostrap {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
    z-index: 999;
}

.fake-modal-boostrap  .modal-container {
    padding: 60px 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.popup-form .popupboxcreatepa {
    height: unset!important;
}

.popup-form .wp-btn {
    margin-top: 15px
}

.table-trim thead tr th:first-child, .table-trim tbody tr td:first-child {
    padding-left: 0;
}

.table-trim thead tr th:last-child, .table-trim tbody tr td:last-child {
    padding-right: 0;
}

.btn-delete-comment {
    display: none;
}

.comment-item:hover .btn-delete-comment {
    display: inline-block;
}

.select-text-searchable .dropdownsearchbar__ip {
    border-radius: 0;
}

.select-text-searchable .dropdownsearchbar__ip input {
    border: none;
}

.select-text-searchable .dropdownsearchbar__ip--ic {
    right: 1px;
    top: 1px;
    bottom: 1px;
    background: #fff;
    padding: 0;
    line-height: 33px;
    padding-right: 10px;
}

.select-text-searchable .dropdownsearchbar__ip--ic:hover {
    background-color: #fff;
}

.select-text-searchable .widthtth3 {
    width: 200px
}

/*.select-text-searchable span {*/
    /*padding-top: 0;*/
/*}*/

/*.select-text-searchable li span {*/
    /*padding-top: 15px*/
/*}*/

/*.empty-li span {*/
    /*padding: 15px*/
/*}*/

.empty-li span:hover {
    background-color: #fff;
}
.assign-to-me-box__icon {
    border: solid 1px #dfe1e4;
}

.a-filter--group {
}

.a-filter--group-left {
    border-left: 1px dashed #ced4da;
}

/*css modal*/
.ibz-modal-header {
    display: -ms-flexbox;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*border-bottom: 1px solid #e9ecef;*/
    /*border-top-left-radius: .3rem;*/
    /*border-top-right-radius: .3rem;*/
}
.ibz-modal-header .btnclose{
    line-height: 0;
    padding-top: 5px;
}
.modal-390{
    max-width: 390px;
}
.ibz-modal-footer {
    text-align: center;
    margin-top: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;

}
.ibz-modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.ibz-modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 9999!important;
}

.tooltipIbizan li.selected span {
    color: #000000;
    background-color: #edeff2;
}
.rdw-inline-wrapper{
    flex-wrap: wrap;
}

.filtercheckbox {
    line-height: 30px;
    padding: 0 15px 0 35px;
}

.filtercheckbox__icon {
    top: 1px;
    font-size: 18px;
}

.boxchat-fixed {
    position: fixed;
    right: 30px;
    top: 90px;
}

.all-color-blue td, .all-color-blue td span {
    color: #2196f3;
}

.bold-all td, .bold-all td span {
    font-weight: bold;
}

.react-datepicker__header--time {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #e3e4e8;
}

.datetime-picker-footer {
    background: #fff;
    padding: 10px;
    border-top: 1px solid #e3e4e8;
    width: 100%;
}

.datetime-picker-footer .btn {
    min-width: 70px;
}

.deadline-info {
    display: -webkit-box;
}

.ic-circlecheck.custom-advance {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 3px;
    background-color: rgba(0,0,0,0.6);
    width: unset;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

.ic-circlecheck.custom-basic {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 3px;
    background-color: rgba(0,0,0,0.6);
    width: unset;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.vertical-align-middle {
    vertical-align: middle;
}

@media only screen and (max-width: 1200px) {
    .chat-log-content {
        max-width: 80%;
    }
}

@media only screen and (max-width: 1024px) {
    .show-ipad {
        display: block;
    }

    .detailticket .order-1 {
        display: block; }
    .detailticket .statusmobile {
        display: none; }
}

@media only screen and (max-width: 992px) {
    .boxchatmb {
        position: fixed;
        z-index: 9999;
        width: calc(100% - 20px)!important;
        height: calc(100% - 20px);
        left: 50%;
        top: 10px;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 991px) {
    .tabsibizanmobile {
        display: block;
    }

    .detailticket .order-1 {
        display: none;
    }

    .detailticket .statusmobile {
        display: block;
    }

    .hidden-mobile {
        display: none;
    }

    .show-mobile {
        display: block;
    }
}

@media only screen and (max-width: 767px) {

    .flex-wrap-mobile {
        flex-wrap: wrap;
    }

    .hidden-xs {
        display: none;
    }

    .input-range-box {
        width: 100%;
    }

    .full-width-mobile {
        width: 100%
    }

    .full-width-mobile.dpl-flex {
        flex-wrap: wrap;
    }

    .full-width-mobile .filtercheckbox {
        width: 100%
    }

    .full-width-mobile .filtercheckbox {
        margin-top: 10px;
        margin-right: 0;
    }

    .full-width-mobile .filtercheckbox:first-child {
        margin-top: 0
    }

    .popup-form {
        width: 85%;
    }

    .popup-form .widthinput {
        width: 100%;
    }

    .custom-label-time-by {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
    }

    .popup-search-input input {
        width: 100%
    }

    .date-range-responsive .date-picker-container {
        width: 50%;
    }

    .date-range-responsive .date-picker-container .react-datepicker-wrapper, .date-range-responsive .date-picker-container .react-datepicker__input-container {
        width: 100%
    }

    .table-solution .td-solution, .table-solution .td-solution span {
        width: 200px;
    }

    .table-solution .td-description, .table-solution .td-description span {
        width: 400px;
    }
}


@media only screen and (max-width: 567px) {
    .popup-form {
        width: 100%;
    }

    .fake-modal-boostrap .modal-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .date-range-responsive {
        flex-direction: column;
    }

    .date-range-responsive .date-picker-container {
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px
    }

    .react-datepicker__input-container input {
        width: 100%;
    }
}

@media only screen and (max-width: 414px) {
    /*.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button), .react-datepicker__navigation--previous{*/
        /*top: 4px;*/
    /*}*/

    .react-datepicker__portal .show-time-select .react-datepicker__current-month,
    .react-datepicker__portal .show-time-select .react-datepicker-time__header {
        font-size: 1rem;
    }

    .react-datepicker__portal .show-time-select .react-datepicker__day-name,
    .react-datepicker__portal .show-time-select .react-datepicker__day,
    .react-datepicker__portal .show-time-select .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }

    /*.react-datepicker {*/
        /*height: 370px;*/
    /*}*/

    .react-datepicker__portal {
        flex-direction: column;
    }
}

@media only screen and (max-width: 375px) {
    .react-datepicker__portal .show-time-select .react-datepicker__day-name,
    .react-datepicker__portal .show-time-select .react-datepicker__day,
    .react-datepicker__portal .show-time-select .react-datepicker__time-name {
        width: 1.7rem;
        line-height: 1.7rem;
    }
}

@media only screen and (max-width: 320px) {
    .react-datepicker__portal .show-time-select .react-datepicker__day-name,
    .react-datepicker__portal .show-time-select .react-datepicker__day,
    .react-datepicker__portal .show-time-select .react-datepicker__time-name {
        width: 1.2rem;
        line-height: 1.2rem;
    }
}

.textCustomerLevel  {
    height: auto !important;
}

._customerLevel .dropdown__title div {
    white-space: inherit !important;
    overflow: inherit !important;
}

.table tbody tr td {
    white-space: normal !important;
}

