.float-label {
  position: relative;
  margin-bottom: 14px;

}


.ant-picker-large {
  +.label {
    top: 10px;
  }
  +.label-float {
    top: -8px !important;
  }
}

.ant-input-number-lg {
  +.label {
    top: 9px;
  }
  +.label-float {
    top: -10px !important;
  }
}


.label {
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 14px;
  color: #7D7E7E;
  transition: 0.2s ease all;
  border-radius: 99px;
  
}

.label-float {
  top: -8px;
  left: 8px;
  font-size: 12px;
  padding: 0 6px;
  margin-bottom: 4px;
  background: #ffffff;
  color: #7D7E7E;
  z-index: 1;
}

