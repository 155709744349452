.filter-form {
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0 2px 14px #E6E6E6;
  border-radius: 6px;

  .title {
    margin-bottom: 14px;
    color: $txt-color-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
  }
}

.staff-filter-form {
  @extend .filter-form;
  margin: 0;
  box-shadow: none;
  border-radius: 6px;
}
