// media aliases and breakpoints
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-table-min: 1025px;

$screen-xs-max: 575.98px;
$screen-sm-max: 767.98px;
$screen-md-max: 991.98px;
$screen-lg-max: 1199.98px;
$screen-xl-max: 5000px;
$screen-table-max: 1023.98px;

// media devices
@mixin xs {
    @media screen and (max-width: #{$screen-xs-max}) {
      @content;
    }
  }
  
  @mixin sm {
    @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
      @content;
    }
  }
  
  @mixin md {
    @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
      @content;
    }
  }
  
  @mixin lg {
    @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
      @content;
    }
  }
  
  @mixin xl {
    @media screen and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
      @content;
    }
  }
  
  // media less than queries
  @mixin lt-sm {
    @media screen and (max-width: #{$screen-xs-max}) {
      @content;
    }
  }
  
  @mixin lt-md {
    @media screen and (max-width: #{$screen-sm-max}) {
      @content;
    }
  }
  
  @mixin lt-lg {
    @media screen and (max-width: #{$screen-md-max}) {
      @content;
    }
  }
  
  @mixin lt-tablet {
    @media screen and (max-width: #{$screen-table-max}) {
      @content;
    }
  }
  
  @mixin lt-xl {
    @media screen and (max-width: #{$screen-lg-max}) {
      @content;
    }
  }
  
  // media great than queries
  @mixin gt-xs {
    @media screen and (min-width: #{$screen-sm-min}) {
      @content;
    }
  }
  
  @mixin gt-sm {
    @media screen and (min-width: #{$screen-md-min}) {
      @content;
    }
  }
  
  @mixin gt-md {
    @media screen and (min-width: #{$screen-md-min}) {
      @content;
    }
  }
  
  @mixin gt-tablet { // tablet and more
    @media screen and (min-width: #{$screen-table-max}) {
      @content;
    }
  }
  
  @mixin gt-lg {
    @media screen and (min-width: #{$screen-xl-min}) {
      @content;
    }
  }
  

.only-show-from-lg {
    @include lt-lg {
        display: none !important;
    }
}

.only-show-from-table {
    @include lt-tablet {
        display: none !important;
    }
}

.only-show-from-md {
    @include lt-md {
        display: none !important;
    }
}

.only-show-from-sm {
    @include lt-sm {
        display: none !important;
    }
}

.only-show-to-xs {
    @include gt-xs {
        display: none;
    }
}

.only-show-to-sm {
    @include gt-sm {
        display: none !important;
    }
}

.only-show-to-md {
    @include gt-md {
        display: none;
    }
}

.only-show-to-table {
    @include gt-tablet {
        display: none;
    }
}

.sm-mg-t-8 {
    @include lt-md {
        margin-top: 8px;
    }
}
