@import 'resources/scss/custom.scss';

@import './resources/css/fonts.css';
@import './resources/css/uikits.css';
@import './resources/css/main.css';
@import './resources/css/custom.css';

// //import css
@import '../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
@import '../node_modules/bootstrap-daterangepicker/daterangepicker.css';
@import '../src/components/ToastMessage/dist/ReactToastify.css';
@import 'react-datepicker/dist/react-datepicker.css';
@import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


