.ant-table-thead {
    > tr {
        align-items: center;
        > th {
            white-space: nowrap;
            font-size: $txt-size-h7;
            background-color: #f2f2f2;
            padding: 8px 16px;
            &:before {
                display: none;
            }
        }
    }
}
.ant-table-tbody {
    > tr {
        > td {
            vertical-align: middle;
        }
    }
}

.ant-table {
    .ant-table-title {
        padding: 0;
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
        > .flex {
            flex-wrap: wrap;
            gap: 12px;
        }

        .total {
            background-color: $primary-color-400;
            color: #fff;
            border-radius: 20px;
            padding: 0 8px;
            margin-left: 6px;
            height: 22px;
            line-height: 22px;
            font-size: 12px;
        }
    }
}

.ant-table {
    .ant-table-thead > tr > th {
        border-bottom: none;
        background: #f6f6f6;
        color: $secondary-color;
        padding: 8px 12px;
        white-space: nowrap;
        font-weight: 500;
        font-size: 12px;
    }

    .ant-table-container {
        table {
            border-collapse: separate;
            // border-spacing: 0 4px;

            > thead {
                > tr:first-child {
                    th:first-child {
                        border-top-left-radius: 4px;
                    }

                    th:last-child {
                        border-top-right-radius: 4px;
                    }
                }

                .ant-table-cell {
                    &.ant-table-column-sort {
                        .ant-table-column-sorters {
                            .ant-table-column-title {
                                flex: initial;
                            }
                        }
                    }
                }
            }

            .ant-table-row-expand-icon-cell {
                padding-left: 0;
                padding-right: 0;
            }

            // > .ant-table-body {
            //     > table {
            //         border-spacing: 0 4px !important;
            //     }
            // }
        }
    }

    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }

    .ant-table-tbody {
        > tr {
            > td {
                border-color: #f1f1f1;
                padding: 6px 12px;
                vertical-align: middle;

                &.ant-table-cell-row-hover {
                    background: #e5fce5;
                }
            }
            .ant-table-row-expand-icon-cell {
                > span {
                    @media screen and (max-width: 1281px) {
                        font-size: 1px;
                        > div {
                            width: 8px;
                        }
                    }
                }

                > span > div {
                    width: 12px;
                    color: $txt-color-primary;
                    padding: 0 0 65px 0;
                }
                > div > i {
                    font-weight: 900;
                }
            }
        }

        .ant-table-row {
            td {
                padding: 14px 12px;
                border: none;

                @include gt-tablet {
                    max-width: 35vw;
                }

                &:first-of-type {
                    border-start-start-radius: 8px;
                    border-end-start-radius: 8px;
                }

                &:last-of-type {
                    border-start-end-radius: 8px;
                    border-end-end-radius: 8px;
                }
            }

            &:nth-child(odd) {
                td {
                    background: #f6f6f6;
                }
            }

            .actions {
                width: 48px;

                button {
                    height: 14px;
                    line-height: 14px;
                }

                * {
                    display: none;
                }
            }

            &:hover {
                .actions {
                    * {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .ant-table-tbody > tr:last-child {
        > td {
            border-bottom: 1px solid transparent;
        }
    }
}
