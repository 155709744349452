.ant-form {
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    position: absolute;
    top: 0;
    right: -3px;
    color: #ff4d4f;
    line-height: 1.65;
    content: '*';
  }

  .ant-form-item {
    margin-bottom: 12px;

    &-label {
      color: $txt-color-black3;
      font-family: $robotofont;
      font-weight: 400;
    }
  }
}

.ant-form-item-with-help .ant-form-item-explain {
  padding-left: 12px;
}
