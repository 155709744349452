.ant-btn-default {
  border: 1px solid var(--greyscale-new-border, #C0C0C0);
  color: $txt-color-primary !important;

  &:hover {
    // background-color: $bg-color-primary !important;
    border: 1px solid transparent;
    background: var(--m-33400, #339190);
    color: #FFFFFF !important;
  }
}

.ant-btn-primary {
  @apply text-white bg-primary;

  &:hover {
    // box-shadow: 0 0 0 2px #B0CCCB;
  }
}

.ant-btn-ghost {
  border: 1px solid var(--greyscale-new-border, #C0C0C0);
  background: var(--greyscale-new-white, #FFF);
  color: var(--greyscale-new-darker, #333);

  &:hover {
    border: 1px solid var(--greyscale-new-border, #C0C0C0);
    color: var(--greyscale-new-darker, #333);
    // @apply text-white bg-cancel-secondary border-cancel-secondary
  }
}

button[type=button] {
  text-transform: capitalize;
}