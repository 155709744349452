/*
 Banghv-24-7
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body, html {
    height: 100%;
}

body {
    font-family: "Roboto", sans-serif;
    overflow-x: hidden;
    position: relative;
}

a {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

a:hover {
    text-decoration: inherit;
}

a:focus {
    outline: none;
}

button:focus, input:focus, select:focus, div:focus, a:focus {
    outline: none !important;
    box-shadow: none !important;
}

ul:after {
    content: '';
    display: block;
    clear: both;
}

b {
    font-weight: bold !important;
}

em {
    font-style: italic !important;
}

/***********************************************
				    COMMON
***********************************************/
.utmhelve-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.utmhelve-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}

.txt-size-h1 {
    font-size: 30px;
}

.txt-size-h2 {
    font-size: 28px;
}

.txt-size-h3 {
    font-size: 26px;
}

.txt-size-h4 {
    font-size: 20px;
}

.txt-size-h5 {
    font-size: 18px;
}

.txt-size-h6 {
    font-size: 16px;
}

.txt-size-h7 {
    font-size: 14px;
}

.txt-size-h8 {
    font-size: 12px;
}

.txt-size-h9 {
    font-size: 10px;
}

.line-height134 {
    line-height: 1.34;
}

.line-height167 {
    line-height: 1.67;
}

.txt-color-white {
    color: #fff;
}

.txt-color-orange {
    color: #fc644c;
}

.txt-color-black {
    color: #000000;
}

.txt-color-black2 {
    color: #666666;
}

.txt-color-gray {
    color: #adadad;
}

.txt-color-gray2 {
    color: #607d8b;
}

.txt-color-pink {
    color: #e91e63;
}

.txt-color-green2 {
    color: #2196f3;
}

.txt-color-blue {
    color: #2196f3;
}


.txt-color-red {
    color: #fc644c;
}

.txt-color-red2 {
    color: #e91e63;
}

.txt-color-yellow {
    color: #ffc107;
}

.txt-color-green {
    color: #4caf50;
}

.txt-color-yellow2 {
    color: #ff9800;
}

.txt-color-green3 {
    color: #008080;
}

.txt-color-green4 {
    color: #007574;
}

.txt-color-primary {
    color: #007574;
}

.bg-transparent {
    background: transparent;
}

.bg-color-white {
    background-color: #fff;
}

.bg-comment-internal {
    background-color: #9898983b;
}

.bg-color-black {
    background-color: #2f3439;
}

.bg-color-gray {
    background-color: #9e9e9e;
}

.bg-color-gray2 {
    background-color: #607d8b;
}

.bg-color-gray3 {
    background-color: #edeff2;
}

.bg-color-gray4 {
    background-color: #f9f9f9;
}

.bg-color-gray5 {
    background-color: #fafafa;
}

.bg-color-yellow {
    background-color: #ffc107;
}

.bg-color-yellow2 {
    background-color: #fffce0;
}

.bg-color-yellow3 {
    background-color: #ff9800;
}

.bg-color-red {
    background-color: #e91e63;
}

.bg-color-green {
    background-color: #4caf50;
}

.bg-color-orange {
    background-color: #fc644c;
}

.bg-color-brightblue {
    background-color: #00bcd4;
}

.txt-uppercase {
    text-transform: uppercase;
}

.txt-lowercase {
    text-transform: lowercase;
}

.txt-capitalize {
    text-transform: capitalize !important;
}

.txt-transform-none {
    text-transform: none !important;
}

.txt-underline {
    text-decoration: underline;
}

.txt-linethrough {
    text-decoration: line-through;
}

.txt-overline {
    text-decoration: overline;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right !important;
}

.txt-center {
    text-align: center;
}

.txt-justify {
    text-align: justify;
}

.txt-nowrap {
    white-space: nowrap;
}

.txt-initial {
    white-space: initial;
}

.dpl-none {
    display: none;
}

.dpl-block {
    display: block;
}

.dpl-il-block {
    display: inline-block;
}

.dpl-flex {
    display: flex;
}

.dpl-none-mobile {
    display: block;
}

.dpl-none-pc {
    display: none;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.overflow-hidden {
    overflow: hidden;
}

.overflowy-hidden {
    overflow-y: hidden;
}

.overflow-auto {
    overflow: auto;
}

.wordbreakall {
    word-break: break-all;
}

.box-shadow1 {
    -webkit-box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.1);
}

.pd30 {
    padding: 30px;
}

.pd20 {
    padding: 20px;
}

.pd25 {
    padding: 25px;
}

.pd15 {
    padding: 15px;
}

.pd10 {
    padding: 10px;
}

.pd5 {
    padding: 5px;
}

.pdl0 {
    padding-left: 0;
}

.pdl5 {
    padding-left: 5px;
}

.pdl10 {
    padding-left: 10px;
}

.pdl15 {
    padding-left: 15px;
}

.pdl20 {
    padding-left: 20px !important;
}

.pdl25 {
    padding-left: 25px;
}

.pdl30 {
    padding-left: 30px;
}

.pdl35 {
    padding-left: 35px;
}

.pdl40 {
    padding-left: 40px;
}

.pdl45 {
    padding-left: 45px;
}

.pdl50 {
    padding-left: 50px;
}

.pdl55 {
    padding-left: 55px;
}

.pdl65 {
    padding-left: 65px;
}

.pdl60 {
    padding-left: 60px;
}

.pdl80 {
    padding-left: 80px;
}

.pdl95 {
    padding-left: 95px;
}

.pdl100 {
    padding-left: 100px;
}

.pdt140 {
    padding-top: 140px;
}

.pdt120 {
    padding-top: 120px;
}

.pdt80 {
    padding-top: 80px;
}

.pdt70 {
    padding-top: 70px;
}

.pdt60 {
    padding-top: 60px;
}

.pdt50 {
    padding-top: 50px;
}

.pdt40 {
    padding-top: 40px;
}

.pdt35 {
    padding-top: 35px;
}

.pdt30 {
    padding-top: 30px;
}

.pdt25 {
    padding-top: 25px;
}

.pdt20 {
    padding-top: 20px;
}

.pdt15 {
    padding-top: 15px;
}

.pdt10 {
    padding-top: 10px;
}

.pdt8 {
    padding-top: 8px;
}

.pdt5 {
    padding-top: 5px;
}

.pdt3 {
    padding-top: 3px;
}

.pdt4 {
    padding-top: 4px;
}

.pdt0 {
    padding-top: 0;
}

.pdr0 {
    padding-right: 0;
}

.pdr5 {
    padding-right: 5px;
}

.pdbt6 {
    padding-bottom: 6px;
}

.pdr10 {
    padding-right: 10px;
}

.pdr15 {
    padding-right: 15px;
}

.pdr20 {
    padding-right: 20px;
}

.pdr25 {
    padding-right: 25px;
}

.pdr30 {
    padding-right: 30px;
}

.pdr40 {
    padding-right: 40px;
}

.pdr45 {
    padding-right: 45px;
}

.pdr50 {
    padding-right: 50px;
}

.pdr60 {
    padding-right: 60px;
}

.pdr80 {
    padding-right: 80px;
}

.pdr100 {
    padding-right: 100px;
}

.pdbt200 {
    padding-bottom: 100px;
}

.pdbt105 {
    padding-bottom: 105px;
}

.pdbt70 {
    padding-bottom: 70px;
}

.pdbt60 {
    padding-bottom: 60px;
}

.pdbt50 {
    padding-bottom: 50px;
}

.pdbt40 {
    padding-bottom: 40px;
}

.pdbt35 {
    padding-bottom: 35px;
}

.pdbt30 {
    padding-bottom: 30px;
}

.pdbt25 {
    padding-bottom: 25px;
}

.pdbt24 {
    padding-bottom: 24px;
}

.pdbt20 {
    padding-bottom: 20px;
}

.pdbt15 {
    padding-bottom: 15px;
}

.pdbt10 {
    padding-bottom: 10px;
}

.pdbt5 {
    padding-bottom: 5px;
}

.pdbt0 {
    padding-bottom: 0px;
}

.mgau {
    margin: auto;
}

.mgbt90 {
    margin-bottom: 90px;
}

.mgbt80 {
    margin-bottom: 80px;
}

.mgbt70 {
    margin-bottom: 70px;
}

.mgbt60 {
    margin-bottom: 60px;
}

.mgbt50 {
    margin-bottom: 50px;
}

.mgbt55 {
    margin-bottom: 55px;
}

.mgbt45 {
    margin-bottom: 45px;
}

.mgbt40 {
    margin-bottom: 40px;
}

.mgbt35 {
    margin-bottom: 35px;
}

.mgbt30 {
    margin-bottom: 30px;
}

.mgbt25 {
    margin-bottom: 25px;
}

.mgbt20 {
    margin-bottom: 20px;
}

.mgbt15 {
    margin-bottom: 15px;
}

.mgbt10 {
    margin-bottom: 10px;
}

.mgbt6 {
    margin-bottom: 6px;
}

.mgbt5 {
    margin-bottom: 5px;
}

.mgbt0 {
    margin-bottom: 0;
}

.mgl280 {
    margin-left: 280px;
}

.mgl25 {
    margin-left: 25px;
}

.mgl20 {
    margin-left: 20px;
}

.mgl15 {
    margin-left: 15px;
}

.mgl10 {
    margin-left: 10px;
}

.mgln10 {
    margin-left: -10px;
}

.mgt5 {
    margin-top: 5px;
}

.mgt10 {
    margin-top: 10px;
}

.mgt12 {
    margin-top: 12px;
}

.mgt15 {
    margin-top: 15px;
}

.mgt20 {
    margin-top: 20px;
}

.mgt25 {
    margin-top: 25px;
}

.mgt30 {
    margin-top: 30px;
}

.mgt35 {
    margin-top: 35px;
}

.mgt40 {
    margin-top: 40px;
}

.mgt45 {
    margin-top: 45px;
}

.mgt50 {
    margin-top: 50px;
}

.mgt60 {
    margin-top: 60px;
}

.mgr5 {
    margin-right: 5px;
}

.mgr10 {
    margin-right: 10px;
}

.mgr15 {
    margin-right: 15px;
}

.mgr20 {
    margin-right: 20px;
}

.mgr25 {
    margin-right: 25px;
}

.mgr30 {
    margin-right: 30px;
}

.mgr35 {
    margin-right: 35px;
}

.mgr40 {
    margin-right: 40px;
}

.mgr45 {
    margin-right: 45px;
}

.mgr50 {
    margin-right: 50px;
}

.mgr55 {
    margin-right: 55px;
}

.mgr85 {
    margin-right: 85px;
}

.border-bootom-1x {
    border-bottom: 1px solid;
}

.border-top-1x {
    border-top: 1px solid;
}

.border-right-1x {
    border-right: 1px solid;
}

.bd0 {
    border: 0px !important;
}

.bd1px {
    border: 1px solid;
}

.bd-color-black {
    border-color: #2f3439;
}

.bd-color-gray {
    border-color: #dfe1e4;
}

.bd-color-white {
    border-color: #ffffff;
}

.bd-rdu0 {
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
}

.bd-rdu {
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.bd-rdu4 {
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.bd-rdu3 {
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
}

.bd-rdu7 {
    -webkit-border-radius: 7px;
    -khtml-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
}

.bd-rdu15 {
    -webkit-border-radius: 15px;
    -khtml-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px;
}

.bd-rdu50 {
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

.position-re {
    position: relative;
}

.position-ab {
    position: absolute;
}

.position-fix {
    position: fixed;
}

.zindex9 {
    z-index: 9;
}

.zindex99 {
    z-index: 99;
}

.zindex999 {
    z-index: 999;
}

.zindex9999 {
    z-index: 9999;
}

.fll {
    float: left;
}

.flr {
    float: right;
}

.clearboth {
    clear: both;
}

.width100 {
    width: 100%;
}

.width50 {
    width: 50%;
}

.width45 {
    width: 45%;
}

.width90 {
    width: 90%;
}

.width80 {
    width: 80%;
}

.width70 {
    width: 70%;
}

.width20 {
    width: 20%;
}

.width10 {
    width: 10%;
}

.widthinput {
    width: 300px;
}

.widthinput2 {
    width: 130px;
}

.widthselect {
    width: 195px;
}

.widthtextarea {
    width: 600px;
}

.height100 {
    height: 100%;
}

.minheight100 {
    min-height: calc(100vh - 110px);
}

.minheight90 {
    min-height: calc(100vh - 90px);
}

.minheight80 {
    min-height: calc(100vh - 80px);
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-nodrop {
    cursor: no-drop;
}

.whitespace {
    white-space: nowrap;
}

.whitespace-initial {
    white-space: initial;
}

sup {
    font-size: 10px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.opacity0 {
    opacity: 0;
}

.opacity1 {
    opacity: 0.1;
}

.opacity3 {
    opacity: 0.3;
}

.opacity5 {
    opacity: 0.5;
}

.dplnumber {
    font-size: 10px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    padding: 5px 7px;
    -webkit-border-radius: 12px;
    -khtml-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border-radius: 12px;
}

.dplstatus {
    display: inline-block;
    font-size: 14px;
    padding: 5px 11px 6px;
}

.imgthumb30 {
    width: 30px;
    height: 30px;
}

.imgthumb40 {
    width: 40px;
    height: 40px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.imgthumb70 {
    width: 70px;
    height: 70px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.label80 {
    width: 80px;
}

.label90 {
    width: 90px;
}

.label100 {
    width: 100px;
}

.label110 {
    width: 110px;
}

.label200 {
    width: 200px;
}

.label150 {
    width: 150px;
}

.label140 {
    width: 140px;
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
    height: 33px;
    line-height: 33px;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 14px;
    color: #000000;
    font-family: "Roboto", sans-serif;
    background-color: #fff;
    padding-right: 22px;
}

/*input[type="text"]:hover,*/
/*input[type="email"]:hover,*/
/*input[type="password"]:hover,*/
/*select:hover,*/
/*textarea:hover {*/
/*    border: 1px solid #008080; }*/
/*input[type="text"]:focus,*/
/*input[type="email"]:focus,*/
/*input[type="password"]:focus,*/
/*select:focus,*/
/*textarea:focus {*/
/*    border: 1px solid #008080; }*/

/*input:disabled {*/
/*    background-color: #fafafa;*/
/*    cursor: not-allowed; }*/
/*input:disabled:hover {*/
/*    border-color: #dfe1e4; }*/

.requireip {
    position: absolute;
    left: 10px;
    top: 14px;
}

select {
    line-height: 23px;
    padding-bottom: 2px;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

.ic-search {
    right: 10px;
    top: 6px;
}

.ic-closeimg {
    top: 2px;
    right: 2px;
    cursor: pointer;
}

.ic-closeimg i {
    font-size: 15px;
}

.ic-closeimg:hover {
    color: #e91e63;
}

.ic-sort {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
}

.wpinputfile__box:hover .ic-closeimg, .wpinputfile__box:hover .overlay {
    display: block;
}

.wpinputfile__box--file {
    width: 70px;
    height: 70px;
    line-height: 70px;
    background-color: #fafafa;
}

.wpinputfile .overlay {
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.ip-error input, .ip-error select, .ip-error textarea {
    border: 1px solid #fc644c;
}

.ip-error:hover input, .ip-error:hover select, .ip-error:hover textarea {
    border: 1px solid #fc644c;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 28px;
}

.ic-down {
    top: 10px;
    right: 10px;
}

.ic-circlecheck {
    width: 15px;
    height: 15px;
    line-height: 13px;
    top: -3px;
    left: -7px;
}

.checkboxdisable i {
    color: #adadad;
}

textarea {
    height: 100px;
    resize: none;
    line-height: 1.34;
}

textarea:focus {
    outline: none;
}

.wpinputfile .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.wpinputfile label {
    position: relative;
    width: 70px;
    height: 70px;
    line-height: 180px;
    text-align: center;
    padding: 0 !important;
    background-color: transparent;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.wpinputfile label:before {
    content: "";
    width: 70px;
    height: 70px;
    position: absolute;
    border: 1px dashed #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    top: 0;
    bottom: 0;
    left: 0;
    right: -8px;
}

.wpinputfile label i {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 50%;
    margin-left: -15px;
    color: #dfe1e4;
}

.attachfiles {
    width: 250px;
}

.attachfiles:hover {
    background-color: #efefef;
}

.attachfiles__ic {
    left: 7px;
    top: 6px;
}

.attachfilestd {
    width: auto;
}

.pagination__wpicon {
    display: inline-block;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
}

.pagination__wpicon:hover {
    color: #000000;
    background: #ebebeb;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ebebeb 0%, #ebebeb 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ebebeb 0%, #ebebeb 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ebebeb 0%, #ebebeb 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebebeb', endColorstr='#ebebeb', GradientType=0);
    /* IE6-9 */
}

.table {
    margin-bottom: 0;
}

.table thead tr th {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #333333;
    font-size: 14px;
    padding: 17px 10px;
    border-bottom: 1px solid #dfe1e4;
    border-top: 1px solid #dfe1e4;
    white-space: nowrap;
    background: #0080801C 0% 0% no-repeat padding-box;
}

.table tbody tr td {
    white-space: nowrap;
}

.table__name {
    max-width: 300px;
    white-space: inherit !important;
}

.table__infotd {
    max-width: 300px;
}

.table__collapse td {
    border-top: 0;
    padding-bottom: 20px;
}

.table__bottom .pagination li {
    border: 1px solid #dee2e6;
}

.table__bottom .pagination li a {
    border: 0;
}

.table__bottom .pagination li:hover {
    background-color: #fff;
    border: 1px solid #008080;
    color: #008080;
}

.table__bottom .pagination li:hover a {
    background-color: #fff;
    color: #008080;
}

.table__bottom .pagination .active {
    background-color: #fff;
    border: 1px solid #008080;
    color: #008080;
}

.whitespace-inherit {
    white-space: inherit !important;
}

.tooltipIbizan {
    position: absolute;
    top: 35px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.tooltipIbizan span {
    padding: 15px;
}

.tooltipIbizan span:hover {
    color: #000000;
    background-color: #edeff2;
}

.tootiplanguage {
    right: -75%;
    top: -10px;
    width: 100px;
    height: 40px;
    z-index: 9;
}

.tooltiptop:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent #dddddd transparent;
}

.tooltiptop:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
}

.tooltipleft:before {
    content: "";
    position: absolute;
    left: -20px;
    top: 10px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent #dddddd transparent transparent;
}

.tooltipleft:after {
    content: "";
    position: absolute;
    left: -18px;
    top: 10px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent;
}

.widthtth3 {
    width: 110px;
}

.daterangepicker td {
    font-family: 'UTMhelve-regular', sans-serif;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #008080;
}

.btn-primary {
    color: #fff;
    background: #008080;
    /* Old browsers */
    background: -moz-linear-gradient(top, #008080 0%, #008080 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #008080 0%, #008080 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #008080 0%, #008080 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09819', endColorstr='#ff5858', GradientType=0);
    /* IE6-9 */
}

.ratestar {
    margin-left: -7px;
}

.ratestar > li {
    position: relative;
}

.ratestar > li span img {
    width: 38px;
    height: 23px;
}

/*.ratestar > li:hover:before, .rating > li:hover ~  li:before {
    content: "";
    position: absolute;
    background: url(../images/favourites-01.svg);
	width: 25px;
	height: 25px;
    left: 0;
}*/
.norates__img img {
    width: 80px;
    height: 75px;
}

.popupboxrate {
    width: 390px;
    z-index: 99991;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 1px solid #dfe1e4;
}

.popupboxrate .wp-btn {
    margin-top: 10px !important;
}

.btnclose {
    width: 24px;
    height: 24px;
    line-height: 24px;
    top: -5px;
    right: -8px;
    border: 1px solid #e0e2e5;
}

.pouprate__form textarea {
    height: 68px;
}

.pouprate__form .wp-btn {
    margin-top: 20px !important;
}

.wrapperpoup {
    overflow-y: hidden;
}

.wrapperpoup .popupboxrate, .wrapperpoup .overlay {
    display: block;
}

.wrappertrash .popupboxtrash, .wrappertrash .overlay {
    display: block;
}

/*.tableshowmore{
	margin-top: -15px;
}*/
.notifyscv {
    width: 400px;
    min-height: 160px;
    padding: 20px;
    top: 80px;
    right: 30px;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #fff;
    z-index: 9999;
}

.circlewarning {
    position: absolute;
    width: 14px !important;
    height: 14px;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #fff;
    top: -5px;
    left: -5px;
}

@media only screen and (min-width: 1920px) {
    .table__name {
        width: 500px;
        white-space: inherit !important;
    }

    .table__infotd {
        width: 400px;
    }
}

@media only screen and (max-width: 1440px) {
    .widthselect {
        width: 140px;
    }
}

@media only screen and (max-width: 1366px) {
    .widthselect {
        width: 160px;
    }
}

@media only screen and (max-width: 1280px) {
    .txt-size-h7 {
        font-size: 12px;
    }

    .dplstatus {
        font-size: 12px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    select {
        font-size: 12px;
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 12px;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 12px;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        font-size: 12px;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        font-size: 12px;
    }

    .widthselect {
        width: 120px;
    }

    .widthinput {
        width: 240px;
    }

    .filter__remove {
        display: none;
    }

    .ic-removeflter {
        display: block;
    }

    .table {
        /* &__infoth{display: none;}
        &__infotd{
            width: auto;
            h3{
                display: none;
            }
            &-responive{
                display: block;
            }
        } */
    }

    .table thead tr th {
        font-size: 12px;
    }

    .ratestar li.mgr15 {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 1024px) {
    .widthselect {
        width: 148px;
    }

    .table__name h2, .table__name span {
        width: 250px;
    }

    .table__infotd h3 {
        width: 250px;
    }
}

@media only screen and (max-width: 992px) {
    .dpl-none-mobile {
        display: none;
    }

    .dpl-none-pc {
        display: block;
    }

    .dplstatus {
        font-size: 14px;
    }

    .txt-size-h7 {
        font-size: 14px;
    }

    .width100mobile {
        width: 100%;
    }

    .width100mobile .widthinput {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .widthselect {
        width: 140px;
    }

    .widthinput {
        width: 270px;
    }
}

@media only screen and (max-width: 767px) {
    /*.table{
        &__bottom{
            display: block;
        }
    }*/
    .notifyscv {
        right: 15px;
    }
}

@media only screen and (max-width: 414px) {
    .popupboxrate {
        width: 300px;
    }

    .notifyscv {
        width: 100%;
        right: 0;
    }
}

.btn {
    height: 33px;
    line-height: 33px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    padding: 0 15px;
    /*	border: 0px;
        background-color: transparent;*/
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.btn--green2 {
    border: 1px solid;
    border-color: #008080;
    color: #008080;
    background: #fff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #fff 0%, #fff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fff 0%, #fff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09819', endColorstr='#ff5858', GradientType=0);
    /* IE6-9 */
}

.btn--green2:hover {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #008080;
    /* Old browsers */
    background: -moz-linear-gradient(top, #008080 0%, #008080 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #008080 0%, #008080 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #008080 0%, #008080 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#008080', endColorstr='#008080', GradientType=0);
    /* IE6-9 */
    color: #fff;
}

.btn--green3 {
    color: #fff;
    background: #008080;
    /* Old browsers */
    background: -moz-linear-gradient(top, #008080 0%, #008080 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #008080 0%, #008080 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #008080 0%, #008080 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#008080', endColorstr='#036565', GradientType=0);
    /* IE6-9 */
}

.btn--green3:hover {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #036565;
    /* Old browsers */
    background: -moz-linear-gradient(top, #036565 0%, #036565 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #036565 0%, #036565 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #036565 0%, #036565 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#008080', endColorstr='#008080', GradientType=0);
    /* IE6-9 */
    color: #fff;
}

.btn--orange {
    color: #fff;
    background: #ff5858;
    /* Old browsers */
    background: -moz-linear-gradient(top, #f09819 0%, #ff5858 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f09819 0%, #ff5858 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f09819 0%, #ff5858 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09819', endColorstr='#ff5858', GradientType=0);
    /* IE6-9 */
}

.btn--orange:hover {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #fc644c;
    /* Old browsers */
    background: -moz-linear-gradient(top, #fc644c 0%, #fc644c 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fc644c 0%, #fc644c 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fc644c 0%, #fc644c 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc644c', endColorstr='#fc644c', GradientType=0);
    /* IE6-9 */
    color: #fff;
}

.btn--green {
    color: #fff;
    border-top: 0;
    padding: 0 15px;
    background: #4caf50;
    /* Old browsers */
    background: -moz-linear-gradient(top, #8bc34a 0%, #4caf50 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #8bc34a 0%, #4caf50 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #8bc34a 0%, #4caf50 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8bc34a', endColorstr='#4caf50', GradientType=0);
    /* IE6-9 */
}

.btn--green:hover {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #008080;
    /* Old browsers */
    background: -moz-linear-gradient(top, #4caf50 0%, #4caf50 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #4caf50 0%, #4caf50 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4caf50 0%, #4caf50 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4caf50', endColorstr='#4caf50', GradientType=0);
    /* IE6-9 */
    color: #fff;
}

.btn--green-ic {
    padding-left: 30px !important;
    position: relative;
}

.btn--green-ic span {
    position: absolute;
    left: 10px;
}

.btn--yellow {
    color: #fff;
    background-color: #ffc107;
}

.btn--yellow:hover {
    color: #fff;
    background-color: #dca500;
}

.btn__sort {
    height: 30px;
    width: 30px;
    display: block;
    text-align: center;
    line-height: 30px;
}

.btn--disable {
    opacity: 0.5;
    cursor: no-drop;
}

.btn--disable:hover {
    background: transparent;
}

.btn--disable:hover i {
    color: #000000;
}

.btn:disabled {
    cursor: no-drop;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn__loading {
    padding-top: 5px;
}

.btn__loading img {
    width: 20px;
    height: 20px;
}

.mglsidebar {
    margin-left: 80px;
}

.mglsidebarcollapse {
    margin-left: 50px;
}

.sidebar_collapsed {
    width: 80px;
    height: 100%;
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: #008080;
}

.sidebar {
    width: 250px;
    z-index: 999;
    height: 100%;
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: #008080;
}

.sidebar__infouser img {
    width: 38px;
    height: 38px;
}

.sidebar__nav_list span {
    left: 24px;
    top: 11px;
}

.sidebar__nav_list:hover {
    background-color: #036565;
    color: #ffffff;
}

.sidebar__nav_list:hover span {
    color: #ffffff;
}

.sidebar__nav_list:hover:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #036565;
}

.sidebar__nav_listactive {
    background-color: #036565;
    color: #036565;
}

.sidebar__nav_listactive span {
    color: #ffffff;
}

.sidebar__nav_listactive:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #036565;
}

.sidebar__nav_sublist--ic {
    left: 40px;
    top: 16px;
}

.sidebar__nav_sublist li h2:hover a {
    color: #000000;
}

.sidebar__nav_sublist li h2:hover .sidebar__nav_sublist--ic {
    display: block;
}

.sidebar__nav_sublist li h2.active a {
    color: #000000;
}

.sidebar__nav_sublist li h2.active .sidebar__nav_sublist--ic {
    display: block;
}

.sidebar__nav_sublist li a:hover span.txt-size-h7 {
    color: #000000;
}

.sidebar__nav_sublist li a:hover .sidebar__nav_sublist--ic {
    display: inline-block;
}

.sidebar__nav_sublist li a.active span.txt-size-h7 {
    color: #000000;
}

.sidebar__nav_sublist li a.active .sidebar__nav_sublist--ic {
    display: inline-block;
}

.sidebar__bottom {
    bottom: 20px;
}

.sidebar__bottom_icon {
    left: 22px;
    top: -4px;
}

.sidebar__bottom_language:hover .tootiplanguage {
    display: block;
}

.sidebar__logout h2:hover a, .sidebar__logout h2:hover span {
    color: #ffffff;
}

.sidebar__change_pin h2:hover a, .sidebar__change_pin h2:hover span {
    color: #ffffff;
}


.sidebarcollapse {
    width: 50px;
}

.sidebarcollapse .container-fluid {
    padding: 0 5px;
}

.sidebarcollapse .sidebar__nav > h2 {
    padding: 10px 0;
    text-align: center;
}

.sidebarcollapse .sidebar__nav > h2 a {
    display: none;
}

.sidebarcollapse .sidebar__nav > h2 span {
    position: inherit;
    top: 0;
    left: 0;
}

.sidebarcollapse .sidebar__nav a {
    padding: 15px 0;
    text-align: center;
}

.sidebarcollapse .sidebar__nav a span.txt-color-black {
    display: none;
}

.sidebarcollapse .sidebar__nav a span {
    position: inherit;
    top: 0;
    left: 0;
}

.sidebarcollapse .sidebar__nav_sublist {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    top: 0;
    left: 50px;
    width: 200px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.sidebarcollapse .sidebar__nav_sublist a {
    padding: 15px;
    text-align: left;
}

.sidebarcollapse .sidebar__nav_sublist a span {
    display: inline-block;
}

.sidebarcollapse .sidebar__infouser {
    padding-right: 0;
}

.sidebarcollapse .sidebar__infouser li h3 {
    display: none;
}

.sidebarcollapse .sidebar__logout {
    display: none;
}

@media only screen and (max-width: 1440px) {
    .sidebar__bottom {
        bottom: 0;
    }

    .sidebar__bottom ul li {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 1024px) {
    .sidebar {
        display: none;
        z-index: 999;
    }

    .sidebar > .container-fluid {
        padding-left: 7px;
        padding-right: 7px;
    }

    .sidebar__infouser {
        width: 50px;
    }

    .sidebar__infouser h3, .sidebar__infouser h4 {
        display: none;
    }

    .sidebar__nav {
        /* 			>h2{
                        padding: 10px 0;
                        text-align: center;
                        a{
                            display: none;
                        }
                        span{
                            position: inherit;
                            top: 0;
                            left: 0;
                        }
                    }
                    a{
                        padding: 10px 0;
                        text-align: center;
                        span.txt-color-black{
                            display: none;
                        }
                        span{
                            position: inherit;
                            top: 0;
                            left: 0;
                        }
                    } */
        /* &_sublist{
            display: none;
            position: absolute;
            background: $bg-color-white;
            border: 1px solid #ddd;
            top: 0;
            left: 50px;
            width: 200px;
            @include border-radius(5px);
            a{
                padding: 15px;
                text-align: left;
                span{
                    display: inline-block;
                }
            }
        }
        li{
            &:hover{
                .sidebar__nav_sublist{
                    display: block;
                }
            }
        } */
    }

    .sidebar__bottom {
        left: 22px;
    }

    .sidebar__bottom li h2 {
        padding-left: 0;
        left: 50%;
        margin-left: -20px;
    }

    .sidebar__bottom li a, .sidebar__bottom li .la-angle-down {
        display: none;
    }

    .sidebar__bottom_icon {
        position: inherit;
        left: inherit;
        top: inherit;
    }

    .navmobile {
        display: block;
    }

    .mnopen .sidebar {
        display: block;
        width: 250px;
    }

    .mnopen .sidebar__infouser {
        width: 100%;
    }

    .mnopen .sidebar__infouser h3, .mnopen .sidebar__infouser h4 {
        display: block;
    }

    .mnopen .sidebar__nav a {
        padding: 20px 20px 20px 65px;
        text-align: inherit;
    }

    .mnopen .sidebar__nav a span.position-ab {
        position: absolute;
        left: 24px;
        top: 17px;
    }

    .mnopen .sidebar__nav_sublist {
        /* position: unset;
        width: 100%;
        border: 0;
        @include border-radius(0);
        li{
            a{
                padding: 10px 20px 10px 65px;
            }
        } */
    }

    .mnopen .sidebar__bottom {
        left: unset;
    }

    .mnopen .sidebar__bottom li h2 {
        padding-left: 65px;
        left: unset;
        margin-left: unset;
    }

    .mnopen .sidebar__bottom li a, .mnopen .sidebar__bottom li .la-angle-down {
        display: inline-block;
    }

    .mnopen .sidebar__bottom_icon {
        position: absolute;
        left: 22px;
        top: -4px;
    }

    .mnopen .overlay {
        display: block;
        z-index: 99;
    }

    .mglsidebar {
        margin-left: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .sidebarcollapse .sidebar__nav a span.txt-color-black {
        display: block;
    }

    .sidebarcollapse .sidebar__infouser li h3 {
        display: block;
    }

    .sidebarcollapse .sidebar__logout {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .primary .mglsidebar {
        padding: 10px 15px;
    }

    .mnopen .sidebar__nav_sublist li a {
        padding: 10px 20px 10px 65px;
    }
}

.filter__checkbox {
    padding: 9px 6px 10px 29px;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #fff;
}

.filter__checkbox .ic-check {
    top: 5px;
    left: 10px;
}

.filter__checkbox:hover {
    background-color: #607d8b;
    color: #fff;
}

.filter__checkboxactive {
    background-color: #607d8b;
    color: #fff;
}

.filter__checkboxactive:hover {
    background-color: #fff;
    color: #000000;
}

.filtertable:after {
    content: '';
    display: block;
    clear: both;
}

@media only screen and (max-width: 1440px) {
    .filtertable__day {
        display: none;
    }

    .filtertable__dayresponsive {
        display: block;
    }
}

@media only screen and (max-width: 1280px) {
    /* .filter{
        &__checkbox{
            top: 4px;
        }
    } */
}

@media only screen and (max-width: 1024px) {
    .logout-btn-text {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .filter li {
        width: 100%;
    }

    .filter label {
        width: 100%;
    }

    .filter__checkbox {
        width: 100%;
    }

    .filter .btn {
        display: block;
    }

    .widthselect, .widthinput, .widthinput2 {
        width: 100%;
    }

    .ic-removeflter {
        display: none;
    }

    .filtertable li {
        width: 100%;
    }

    .filtertable label {
        width: 100%;
    }

    .filter__remove {
        display: block;
        text-align: center;
    }

    .select-control {
        width: 100%;
    }

    .filter__checkbox {
        margin-bottom: 5px;
    }

    .filtertable .flex-wrap {
        width: 100%;
    }

    .react-bootstrap-daterangepicker-container {
        width: 100%;
    }

    .react-bootstrap-daterangepicker-container .filter-control {
        width: 100%;
        display: flex;
    }

    .input-range-container {
        flex-direction: column;
        width: 100%;
    }

    .input-range-container label {
        padding-bottom: 5px;
    }
}

.tabsibizan a {
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding: 0 15px 0 18px;
    height: 30px;
    line-height: 30px;
    font-size: 14px !important;
}

.tabsibizan a:hover {
    background-color: #008080;
    color: #fff !important;
}

.tabsibizan a span {
    padding: 2px 6px 2px 7px;
    top: -9px;
    right: -9px;
    line-height: initial;
}

.tabsibizan a.active {
    background-color: #008080;
    color: #fff;
}

.tabsibizan__ic a {
    padding-left: 30px !important;
}

.tabsibizan__ic span {
    padding: 0 !important;
    left: 6px;
    top: 6px !important;
}

.tabsibizan li:last-child {
    margin-right: 0;
}

.tabsibizanscroll li {
    min-width: 92px;
}

.tabsibizanscroll li a {
    padding: 0 5px;
    text-align: center;
}

.tabsibizanscroll::-webkit-scrollbar {
    display: none;
}

.tabsibizanmobile {
    display: none;
}

@media only screen and (max-width: 1280px) {
    .tabsibizan a {
        padding: 0 15px 0 18px;
    }

    .tabsibizan__icon {
        top: 3px !important;
    }
}

@media only screen and (max-width: 992px) {
    .tabsibizanmobile {
        display: block;
        margin-bottom: 15px;
    }
}


/*.boxchat {*/
/*    width: 100%;*/
/*    !*height: calc(100% - 120px);*!*/
/*    height: 550px;*/
/*    position: inherit;*/
/*    !*right: 30px;*!*/
/*    padding-right: 7px; }*/

.boxchat {
    /*width: 100%;
    /*height: calc(100% - 120px);*/
    position: inherit;
    /*right: 30px;*/
    padding-right: 7px;
}

.boxchat__top {
    height: 50%;
}

.boxchat__bt {
    height: 35%;
    top: initial !important;
    bottom: 30px;
}

.boxchat__content {
    height: calc(100% - 104px);
    overflow-x: auto;
    margin-top: 10px;
    padding-right: 7px;
}

.boxchat__content::-webkit-scrollbar {
    display: none;
}

.boxchat__content::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e0e1e3;
}

.boxchat__content::-webkit-scrollbar {
    width: 4px;
    background-color: #e0e1e3;
}

.boxchat__content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #008080;
}

.boxchat__content_avt img {
    width: 24px;
    height: 24px;
}

.boxchat__content .imgthumb70 {
    width: 65px;
    height: 65px;
}

.boxchat__content ul:hover .boxchat__content--trash {
    display: block;
}

.boxchat__content:hover::-webkit-scrollbar {
    display: block;
}

.boxchat__content--trash {
    position: absolute;
    right: 14px;
}

.boxchat .ic-camera {
    top: 7px;
    left: 10px;
}

.boxchat .ic-camera:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 18px;
    background-color: #dfe1e4;
    top: 1px;
    right: -6px;
}

.boxchat .ic-send {
    top: 7px;
    right: 2px;
}

.boxchat__ip {
    padding-right: 7px;
}

.boxchat__ip textarea {
    width: 100%;
    height: 33px;
    padding-top: 8px;
    padding-right: 27px;
}

.boxchat__ip textarea::-webkit-scrollbar-track {
    display: none;
}

.boxchat__ip textarea::-webkit-scrollbar {
    display: none;
}

.boxchat__ip textarea::-webkit-scrollbar-thumb {
    display: none;
}

.boxchaticonmb {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #008080;
    z-index: 9;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    bottom: 25px;
    right: 25px;
    -webkit-box-shadow: 0px 0px 39px -5px rgba(252, 100, 76, 0.5);
    -moz-box-shadow: 0px 0px 39px -5px rgba(252, 100, 76, 0.5);
    box-shadow: 0px 0px 39px -5px rgba(252, 100, 76, 0.5);
}

.boxchaticonmb span {
    color: #ffffff;
    font-size: 26px;
}

@media only screen and (max-width: 1440px) {
    .boxchat {
        width: 100%;
    }

    .boxchat span.txt-size-h3 .la-comments {
        display: none;
    }
}

@media only screen and (max-width: 1366px) {
    .boxchat {
        width: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    .boxchat {
        width: 100%;
    }

    .boxchat__content .imgthumb70 {
        width: 35px;
        height: 35px;
    }

    .boxchat .tabsibizan li a {
        padding: 0 10px 0 10px;
    }
}

@media only screen and (max-width: 992px) {
    .boxchatmb {
        z-index: 99;
        width: 95% !important;
        height: calc(100% - 85px);
        left: 50%;
        top: 74px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

.header {
    height: 60px;
}

.header__logo {
    width: 250px;
}

.header__logo a {
    border: 0px;
}

.header__breadcrumb ul > li > a {
    padding-top: 5px;
    display: inline-block;
}

.header__breadcrumb ul li h2 span {
    font-size: 12px;
}

.header__gridlayout span.active {
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.2);
    color: #008080;
}

.header__gridlayout span:hover {
    background-color: transparent;
    -webkit-box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.2);
    color: #008080;
}

.header__right .dplstatus {
    margin-top: -4px;
}

.headercollapse .container-fluid {
    padding: 0;
}

.headercollapse .header__logo {
    width: 50px;
    padding-right: 0;
    text-align: center;
}

.headercollapse .header__btn {
    padding-right: 30px;
}

@media only screen and (max-width: 1280px) {
    .header__btn .btn {
        padding: 0 10px;
    }
}

@media only screen and (max-width: 1024px) {
    .header {
        /*&__gridlayout{
            display: none;
        }*/
    }

    .header__logo {
        display: none;
    }

    .header__right {
        padding-left: 0;
    }

    .header__btn {
        padding-right: 0;
    }

    .header__breadcrumb {
        display: none;
    }

    .headercollapse .container-fluid {
        padding: 0 15px;
    }

    .headercollapse .header__btn {
        float: right;
        padding-right: 0;
    }

    .headercollapse .header__gridlayout {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .header {
        /*&__gridlayout{
            display: none;
        }*/
    }

    .header__logo {
        display: none;
    }

    .header__right {
        padding-left: 0;
    }

    .header__right ul li a {
        font-size: 12px;
    }

    .header__btn {
        padding-right: 0;
    }
}

.footer {
    min-height: 40px;
    line-height: 40px;
}

.footer__language_txt:hover {
    color: #2196f3;
    text-decoration: underline;
}

.footer__language span.active {
    color: #2196f3;
}

.footercollapse {
    min-height: 30px;
    line-height: 30px;
}

@media only screen and (max-width: 1024px) {
    .footercollapse {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .footer {
        min-height: 20px;
        line-height: 20px;
    }

    .footer p {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 414px) {
    .footer__language {
        display: block;
    }
}

.createform {
    width: 690px;
    margin: auto;
}

@media only screen and (max-width: 767px) {
    .wpcreate .header__breadcrumb {
        display: none;
    }

    .wpcreate .header__btn {
        display: block;
        width: 100%;
        text-align: right;
    }

    .createform {
        width: 100%;
        padding: 15px;
    }

    .createform .width50 {
        width: 100%;
    }
}

.detaillist .table thead tr th {
    border-top: 0;
}

.receivename {
    padding: 6px 20px 6px 26px;
    /*	span{
            padding-top: 3px;
        }*/
}

@media only screen and (max-width: 1440px) {
    .detaillist__form .widthtextarea {
        width: 445px;
    }
}

.detaillistcomplaint {
    height: auto;
}

.detaillistcomplaint table thead tr th {
    border-top: 0;
    padding: 10px 5px;
}

.detaillistcomplaint table tbody tr td {
    padding: 15px 5px 0;
}

.detaillistcomplaint__knnb .table-responsive {
    height: 180px;
    /*	    	.opacity1{
    	    		td{
    	    			padding-bottom: 0;
    	    		}
    	    	}*/
}

.detaillistcomplaint__knnb table tbody tr td {
    border-top: 0;
}

.detaillistcomplaint__tckn .dropdown__list {
    /*max-height: 250px;
    overflow: auto;*/
}

.detaillistcomplaint__tckn .dropdown__list::-webkit-scrollbar-track {
    background-color: #dfe1e4;
}

.detaillistcomplaint__tckn .dropdown__list::-webkit-scrollbar {
    width: 4px;
    background-color: #008080;
}

.detaillistcomplaint__tckn .dropdown__list::-webkit-scrollbar-thumb {
    background-color: #008080;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
}

.detaillistcomplaint__tckn table tbody tr td {
    border-top: 0;
}

.detaillistcomplaint__info {
    /*table{
    	thead{
    		tr{
    			th{
    				padding: 0 5px;
    			}
    		}
    	}
    	tbody{
    		tr{
    			td{
    				border-top: 0;
    			}
    		}
    	}
    }*/
}

.detaillistcomplaint__info .col-md-4 {
    padding: 0 10px;
}

.detaillistcomplaint__paxlform {
    /*.wp-btn{
      padding-right: 93px;
    }*/
}

.detaillistcomplaint__paxlform .dropdown__list {
    /* max-height: 250px;
     overflow: auto;*/
}

.detaillistcomplaint__paxlform .dropdown__list::-webkit-scrollbar-track {
    background-color: #dfe1e4;
}

.detaillistcomplaint__paxlform .dropdown__list::-webkit-scrollbar {
    width: 4px;
    background-color: #008080;
}

.detaillistcomplaint__paxlform .dropdown__list::-webkit-scrollbar-thumb {
    background-color: #008080;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
}

.detaillistcomplaint__paxlform .dropdown__list ul li {
    padding: 15px 10px;
}

.detaillistcomplaint__paxlform .dropdownsearchbar__boxlist {
    padding-right: 1px;
}

.detaillistcomplaint__transaction table thead tr {
    background-color: #edeff2;
}

.detaillistcomplaint__transaction table thead tr th {
    border: 0;
    padding-left: 17px;
}

.detaillistcomplaint__transaction table tbody tr td {
    border: 0;
    padding-left: 17px;
}

.detaillistcomplaint .table-responsive .opacity1 td {
    padding-bottom: 0;
}

.tableshowmore span:hover {
    color: #adadad;
}

.placeholderbig {
    height: 33px;
    border: 0 !important;
    border-bottom: 1px solid #dfe1e4 !important;
    padding-left: 0 !important;
    -webkit-border-radius: 0px !important;
    -khtml-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    border-radius: 0px !important;
}

.placeholderbig::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #008080;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    opacity: .5;
}

.placeholderbig::-moz-placeholder {
    /* Firefox 19+ */
    color: #008080;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    opacity: .5;
}

.placeholderbig:-ms-input-placeholder {
    /* IE 10+ */
    color: #008080;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    opacity: .5;
}

.placeholderbig:-moz-placeholder {
    /* Firefox 18- */
    color: #008080;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    opacity: .5;
}

.placeholderbig span.title {
    color: #008080 !important;
    font-size: 20px !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 600 !important;
    opacity: .5;
}

.placeholderbig .la-calendar {
    color: #008080 !important;
    font-size: 20px !important;
}

@media only screen and (max-width: 992px) {
    .detaillistcomplaint__paxlform .widthtextarea {
        width: 100%;
    }

    .detaillistcomplaint__paxlform label {
        font-weight: 700;
        color: #607d8b;
    }

    .detaillistcomplaint__paxltotal_img {
        display: none;
    }

    .detaillistcomplaint__paxltotalmobile .placeholderbig {
        width: 100%;
        padding-right: 14px;
    }

    /*	.detailcomplaintkh{
            &__top{
                display: block;
                .width80{width: 100%}
                &_img{
                    display: none;
                }
            }
        }*/
}

@media only screen and (max-width: 767px) {
    .popupboxtrash {
        width: 300px;
    }
}

@media screen and (orientation: landscape) and (min-width: 319px) and (max-width: 812px) {
    .popupboxfixed {
        height: calc(100vh - 40px);
    }

    .popupboxfixed .popupboxcreatepa {
        height: calc(100vh - 150px);
        overflow: auto;
    }
}

@media screen and (orientation: landscape) and (min-width: 319px) and (max-width: 1366px) {
    .popupboxtrash {
        width: 300px !important;
        height: auto;
    }

    .popupboxrate {
        height: calc(100vh - 150px);
    }
}

/*@media screen and (orientation:landscape)
and (max-width: 1366px) {
   .popupboxrate{
   		height: calc(100vh - 600px);
   }
}

@media screen and (orientation:landscape)
and (max-width: 1024px) {
   .popupboxrate{
   		height: calc(100vh - 454px);
   }
}*/
.detailticket {
    height: auto;
}

.detailticket .wpstatus {
    padding: 13px 12px 13px 12px;
    -webkit-border-radius: 17px;
    -khtml-border-radius: 17px;
    -moz-border-radius: 17px;
    -ms-border-radius: 17px;
    -o-border-radius: 17px;
    border-radius: 17px;
}

.detailticket .ic-closeimg {
    top: 8px;
    right: 5px;
}

.detailticket .wptextarea textarea {
    height: 60px;
    border: 0px;
}

.detailticket__search_list:hover .ic-closeimg {
    display: block;
}

.detailticket .wptextareanote .textareanote {
    display: none;
}

.detailticket .wptextareanote-edit .textareanote {
    display: block;
}

.detailticket .wptextareanote-edit .wptextareanote__txt {
    display: none;
}

.detailticket__list .active {
    background-color: #fafafa;
}

.detailticket__list ul:hover .detailticket__list--remove {
    display: block;
}

.detailticket__list--remove {
    right: 10px;
    top: 10px;
}

.detailticket__list_comment {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.detailticket__list_commentreadmore {
    -webkit-line-clamp: inherit;
}

.detailticket__list img.imgthumb40 {
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

.detailticket .tabsibizan .active span {
    color: #fff;
}

@media only screen and (max-width: 1800px) {
    .boxchat__bt {
        height: 30%;
    }
}

@media only screen and (max-width: 1366px) {
    .detailticket .wpstatus {
        padding: 13px 3px 13px 10px;
    }

    .boxchat__bt {
        height: 30%;
    }
}

@media only screen and (max-width: 1280px) {
    .detailticket .wpstatus {
        padding: 12px 5px;
    }

    .boxchat__bt {
        height: 30%;
    }
}

@media only screen and (max-width: 1024px) {
    .detailticket .wpstatus {
        padding: 13px 0px 13px 4px;
    }

    .detailticket .order-1 {
        display: block;
    }

    .detailticket .statusmobile {
        display: block;
    }
}

@media only screen and (max-width: 992px) {
    .detailticket .order-1 {
        display: none;
    }

    .wptextareanote ul {
        display: block;
    }

    .wptextareanote label {
        margin-bottom: 10px;
        display: block;
    }

    .wptextarea {
        display: block;
    }

    .wptextarea .width100 {
        height: 95px;
        margin-bottom: 10px;
    }

    .wptextarea .wp-btn {
        display: flex;
        justify-content: flex-end;
    }

    .wptextarea .wp-btn .btn--green3 {
        margin-right: 10px;
    }

    .wpupload ul {
        display: block;
    }

    .wpupload ul li label {
        width: 100%;
        margin-bottom: 10px;
    }

    .ratemobile {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .detailticket .wpstatus {
        padding: 13px 12px 13px 12px;
    }

    .order-1 {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 375px) {
    .detailticket .tabsibizan {
        margin-bottom: 15px;
    }

    .detailticket .imgthumb70 {
        width: 60px;
        height: 60px;
    }

    .detailticket .wpinputfile ul li {
        margin-bottom: 15px;
    }

    .detailticket .wpinputfile__box--file {
        width: 60px;
        height: 60px;
    }
}

.wrapper404 {
    /*	background: url(../images/bg-404.png) no-repeat center bottom;
        height: 100%;*/
}

.wrapper404 .wp404 {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.mglsidebarcollapse {
    position: relative;
    flex-grow: 1;
}

.kanbanwrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    position: absolute;
    top: 30px;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: 30px;
    margin-right: 30px;
}

.kanbancolumn {
    width: 300px;
    margin: 0 7px;
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
}

.kanbancolumn__title {
    padding-right: 10px;
    min-height: 25px;
}

.kanbancolumn__title .dplnumber {
    right: -40px;
    top: 2px;
    min-width: 30px;
    text-align: center;
}

.kanbancolumn__title .ic-sort {
    border: 1px solid #dfe1e4;
}

.kanbancolumn__wrapper {
    /*		height: calc(100% - 40px);
    */
    overflow-y: auto;
    padding-right: 6px;
}

.kanbancolumn__wrapper::-webkit-scrollbar-track {
    background-color: #dfe1e4;
}

.kanbancolumn__wrapper::-webkit-scrollbar {
    display: none;
    width: 4px;
    background-color: #008080;
}

.kanbancolumn__wrapper::-webkit-scrollbar-thumb {
    background-color: #008080;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
}

.kanbancolumn__wrapper:hover::-webkit-scrollbar {
    display: block;
}

.kanbancolumn .kanbancolumnwp {
    padding: 20px 6px 20px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
    white-space: normal;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.kanbancolumn__box {
    border: 1px solid #dfe1e4;
    width: 270px;
}

.kanbancolumn__box_title {
    position: relative;
}

.kanbancolumn__box_title h3 {
    padding-left: 27px;
}

.kanbancolumn__box_title .wpimg {
    left: 0;
    top: -2px;
}

.kanbancolumn__box_title .ic-arrowright .ic-arrowright-tooltip {
    top: -25px;
    left: -100%;
    margin-left: -20px;
}

.kanbancolumn__box_title .ic-arrowright:hover .ic-arrowright-tooltip {
    display: block;
}

.kanbancolumn__box_bottom ul li {
    margin-right: 3px;
}

.kanbancolumn__box_bottom--seemore {
    line-height: 30px;
}

.kanbancolumn__box_bottom .overlay {
    height: 30px;
    line-height: 30px;
}

.kanbancolumn__box_bottom .assignee-icon-container:hover .overlay {
    display: block;
}

.kanbancolumn__box .ic-arrowright {
    display: none;
    position: absolute;
    right: 0;
}

.kanbancolumn__box:hover {
    -webkit-box-shadow: 1px 1px 15px -3px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 1px 1px 15px -3px rgba(0, 0, 0, 0.45);
    box-shadow: 1px 1px 15px -3px rgba(0, 0, 0, 0.45);
}

.kanbancolumn__box:hover .ic-arrowright {
    display: block;
}

.kanbancolumn__box:last-child {
    margin-bottom: 0;
}

.kanbancolumnempty {
    max-height: 100%;
    height: auto;
}

.kanbancolumnempty .kanbancolumn__wrapper {
    overflow: hidden;
}

@media only screen and (max-width: 1024px) {
    .mglsidebarcollapse {
        margin-left: 0;
        min-height: calc(100vh - 60px);
    }

    .kanbanwrapper {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 320px) {
    .kanbancolumn {
        width: 220px;
    }

    .kanbancolumn__box {
        width: 190px;
    }

    .kanbancolumn__box_bottom h4 {
        display: none;
    }
}

.bd-color-gray2 {
    border-color: #f0f0f0 !important;
}

.borderdotted-bootom-2x {
    border-bottom: 2px dotted;
}

.borderdotted-top-2x {
    border-top: 2px dotted;
}

._offenderSelectBox {
    height: 150px !important;
}

._refundValue {
    margin-top: -16px;
}

.pull-right {
    position: absolute;
    right: 0px;
}

.status-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    background: #096dd9;
    margin-right: 5px;
}

/*# sourceMappingURL=main.css.map */
