//Font Family
$robotofont: 'Roboto', sans-serif;

//Font Size
$txt-size-h1: 24px;
$txt-size-h2: 22px;
$txt-size-h3: 20px;
$txt-size-h4: 18px;
$txt-size-h5: 16px;
$txt-size-h6: 15px;
$txt-size-h7: 14px;
$txt-size-h8: 12px;
$txt-size-h9: 10px;

//Color
$txt-color-white: #ffffff;
$txt-color-black: #1A1A1A;
$txt-color-black2: #000000;
$txt-color-black3: #5A5A5A;
$txt-color-gray: rgba(0, 0, 0, 0.45);
$txt-color-gray2: #BDBDBD;
$txt-color-red: #F5222D;
$txt-color-red2: #FF4559;
$txt-color-orange: #faad14;
$txt-color-blue: #005A5A;
$txt-color-blue2: #008DF9;
$txt-color-green: #7CB305;
$txt-color-only-staff: #0F98B1;
$txt-color-yellow: #FFA634;
$txt-color-primary: #007574;
//Background Color
$bg-color-white: #ffffff;
$bg-color-yellow: #FFFBE6;
$bg-color-blue: #005A5A;
$bg-color-blue2: #E6F7FF;
$bg-color-blue3: #A6DBFF;
$bg-color-gray: #f0f2f5;
$bg-color-gray2: #D9D9D9;
$bg-color-gray3: #C7C7C7;
$bg-color-gray4: #FAFAFA;
$bg-color-dark: #011d57;
$bg-color-green: #7CB305;
$bg-color-green2: #DDEFEF;
$bg-color-black: #000000;
$bg-color-gray3: #eaebec;
$bg-color-red2: #F54255;
$bg-color-primary: #007574;
//Border Color
$bd-color-primary: #007574;

// main color of website
$primary-color: #007574;
$secondary-color: #5A5A5A;
$secondary-color-2: #7D7E7E;
$primary-color-400: #339190;
$grey-scale: #F6F6F6;
$text-disable-color: #B1B1B1;
