.staffs-container {
    .ant-tabs-nav {
      border-radius: 6px 6px 0 0;
    }
}

.staff-list {
    margin: 12px 0;
    padding: 16px;
    background-color: #FFF;
    border-radius: 6px;
}

.staff-insertion {
    .ant-form-item-control {
        max-width: 100%;

        .ant-form-item-label {
            width: 32%;
        }
    }
}

.staff-insertion_list-threshold {
    margin-top: 6px;
    padding: 12px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    background: #ddefef;
}

.staff-insertion_list-threshold-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: #0a66b7;
    cursor: pointer;
}
