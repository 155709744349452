
.ant-table-pagination.ant-pagination {
  margin: 24px 0;
}

.ant-pagination-item {
  border: 0;
  color: #707070;

  &:hover {
      background-color: #ddefef;
      color: #007574;
      @include border-radius(4px);
  }
}

.ant-pagination-item-active {
  background-color: #ddefef;
  color: #007574;
  @include border-radius(4px);
}

.ant-pagination-item-link {
  border: 0 !important;
  font-size: $txt-size-h6 !important;

  > .anticon {
      vertical-align: 1px;
  }
}

.ant-pagination-total-text {
  padding-top: 3px;
  font-size: 14px;
  color: #707070;
}

.custom-pagination {
  .ant-pagination-simple-pager {
    > input {
      pointer-events: none;
      border: 1px solid transparent !important;
      background: transparent;
      font-size: 14px;
      padding: 0;
      margin-right: 0
    };
  }
}