.claim-state-tag {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #fff;
    white-space: pre;
    font-size: 12px;
    height: 24px;
    max-width: 110px;
}

.config-column-checkbox {
    &.ant-checkbox-wrapper+.ant-checkbox-wrapper {
        margin-left: 0;
        white-space: pre;
    }
}

.claim-state-tag-filter {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: #EBEBEB;
    color: var(--text-color-text-secondary, #5A5A5A);
    font-size: 12px;
    cursor: pointer;

    &-selected {
        color: #fff;
        background: var(--m-3-primary, #007574);
    }
}

.claim-title-truncate {
    position: relative;
    display: inline;
    max-width: 200px;
    /* Đặt chiều rộng của phần tử để hiển thị */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 1281px) {
        max-width: 300px;
    }
}



.claim-title-truncate:hover .content::before {
    content: attr(data-content);
    /* Lấy nội dung từ thuộc tính data-content */
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 4px;
    z-index: 1;
}

/* (Optional) Tùy chỉnh phần hiển thị tooltip khi hover */
.claim-title-truncate:hover .content::before {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.scroll-buttons {
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 99;

    .ant-btn-default {
        // width: 36px;
        height: auto;
        aspect-ratio: 1/1;
        // white-space: pre-wrap;
        
    }
}