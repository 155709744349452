.is-drop-down {
  .m3-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  }
}

.m3-dropdown {
  float: left;
  overflow: hidden;
  background: #ffffff;

  &--hide {
    display: none;
  }

  &--container {
    width: 200%;
    float: left;
    transition: all .3s;
    position: relative;

    &--moved {
      transform: translateX(-50%);

      .m3-dropdown--main {
        position: absolute;
      }
    }
  }

  &--main {
    float: left;
    width: 50%;

    ul {
      padding: 0 20px;
      margin-top: 4px;

      li {
        line-height: 26px;
        padding: 10px 0;

        & + li {
          border-top: 1px solid #eeeeee;
        }

        a {
          color: #333333;
          font-size: $txt-size-h7;

          &:hover {
            //color: $color--primary;
          }
        }

        span {
          color: #4E4B66;
        }
      }
    }
  }

  &--item {
    float: right;
    width: 50%;
    opacity: 0;
    position: relative;
    z-index: 1;

    &.m3-dropdown--active {
      opacity: 1;
      z-index: 2;
    }

    > p:first-child {
      padding: 5px 10px;
      line-height: 5px;
    }
  }
}
