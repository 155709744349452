html {
  display: flex;
  min-height: 100%;
}

body {
  margin: 0;
  display: flex;
  min-height: 100%;
  width: 100%;
}

.import-dropzone {
  display: inline-block;
  width: 100% !important;
  height: 32px !important;
  color: #949494;

  > div {
    border: none !important;
    width: 100% !important;
    height: 32px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .ant-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    line-height: 30px;
  }
}

.configColumnFinancial {
  width: 450px;
}

.fixed-header {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 9;
  background: #f0f2f5 !important;
  height: unset;
}

.image-preview {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-120 {
  min-width: 200px;
}

.chat-attachments-item {
  display: inline-flex;
  width: 60px;
  height: 60px;

  img {
    width: 100%
  }
}

.fixed-header-content {
  padding-top: 68px;
}

.width-100-percent {
  width: 100%;
}

.width-50-percent {
  width: 50%;
}

.border {
  border: 0.5px solid #dcdcdc;
}

.border-top {
  border-top: 0.5px solid #dcdcdc;
}

.border-bottom {
  border-bottom: 0.5px solid #dcdcdc;
}

.border-left {
  border-left: 0.5px solid #dcdcdc;
}

.border-right {
  border-right: 0.5px solid #dcdcdc;
}

.height-100-percent {
  height: 100%;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.text-center {
  text-align: center;
}

#root, #app-main, .main-body {
  //display: flex;
  //min-height: 100%;
  width: 100%;
  height: 100%;
}

.ant-layout-menu {
  // height: auto !important;
}

.splash-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.header-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.bg-gray {
  background-color: #dcdcdc;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-direction-column {
  flex-direction: column;
}

.icon-error-input {
  position: absolute;
  left: -17px;
  top: 6px;
}

.row-exchange-rate-active {
  background-color: #EFFFE9;
}

.break-word {
  word-break: break-word;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.bold {
  font-weight: bold;
}

.font-medium {
  font-weight: 500;
}

.vertical-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.checkbox-group {
  &.vertical {
    .ant-checkbox-wrapper {
      display: block;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}

.box-checkbox-custom {
  .ant-checkbox-wrapper {
    display: block;
    margin-left: 0;
    margin-bottom: 10px;
  }
}

.line-height-1 {
  line-height: 1;
}

.order-row-fixed {
  bottom: 0;
  height: 100px;
  display: flex;
  align-items: center;
  background: #fff;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.box-chat-wrapper {
  display: flex;

  > .ant-spin-container {
    width: 100%;
  }
}

.camera-dropzone {
  display: inline-block;
  width: 38px !important;
  height: 32px !important;
  position: absolute;
  top: 0;
  z-index: 9;

  > div {
    border: none !important;
    width: 38px !important;
    height: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chat-attachments-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
}

.chat-attachments-item {
  display: inline-flex;
  width: 60px;
  height: 60px;
  margin-right: 5px;
  margin-bottom: 5px;

  img {
    width: 100%
  }
}

.link-visited:visited {
  color: #A5C7EB;
}

.link-visited:active {
  text-decoration: underline;
}

.a:visited {
  color: #A5C7EB;
}

.btn-send-chat {
  display: inline-flex;
  background: #1890ff;
  height: 31px;
  width: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.modal-preview-image {
  .ant-modal-close-x {
    width: 26px;
    height: 30px;
    line-height: 30px;
  }
}

.order-list {
  padding: 0 25px;

  &--header {

    padding: 16px 0;
  }

  &--search {
    margin: 16px 0;
    padding: 10px 0;
  }

  &--item {
    margin: 16px 0 0 0;
    padding: 16px;
    cursor: pointer;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &--footer {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    cursor: pointer;
  }
}

.comment-input {
  padding-right: 25px;
  border: none;
}

.comment-button {
}

.comment {
  background-color: white;
  position: relative;

  .upload-list-inline {
    /* position: absolute;
     top: 100%;
     left: -4px;
     z-index: 1;
     background-color: transparent;
     padding: 3px;*/
    &.ant-upload-list-item {
      background-color: white;
    }
  }
}

.bg-gray {
  background-color: #dcdcdc;
}

.upload-icon {
  height: auto;
  width: auto;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 10;
}

.expand-hidden .ant-table-row-expand-icon-cell span {
  display: none;
  pointer-events: none;
}

.ordertop__search_tag-list {
  line-height: 20px;
  padding: 0 10px;
}

.col-pd0 {
  padding: 0px !important;
}

.transactiontable {
  .ant-table tbody > tr > td {
    border-bottom: 1px solid #a9a4a4;
  }
}

.min-height-35 {
  min-height: 35px;
}

.truncate {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .mgtmb {
    margin-top: 15px;
    padding-right: 10px;
  }
}

/* BORDER RADIUS */
$class: 'bd-r-';
@for $i from 0 through 60 {
  // for each $bd-r-#{i}
  .#{$class}#{$i} {
    border-radius: #{$i}px;
  }
}

/* BORDER TOP */
$class: 'bd-t-';
@for $i from 0 through 60 {
  // for each $bd-t-#{i}
  .#{$class}#{$i} {
    border-top-width: #{$i}px;
  }
}

/* BORDER TOP */
$class: 'bd-b-';
@for $i from 0 through 60 {
  // for each $bd-b-#{i}
  .#{$class}#{$i} {
    border-bottom-width: #{$i}px;
  }
}

/* FONT SIZE */
$class: 'text-';
@for $i from 0 through 60 {
  // for each $text-#{i}
  .#{$class}#{$i} {
    font-size: #{$i}px;
  }
}

/* MARGIN-TOP */
$class: 'mgt-';
@for $i from 0 through 60 {
  // for each $mgt-#{i}
  .#{$class}#{$i} {
    margin-top: #{$i}px;
  }
}

/* MARGIN-RIGHT */
$class: 'mgr-';
@for $i from 0 through 60 {
  // for each $mgr-#{i}
  .#{$class}#{$i} {
    margin-right: #{$i}px;
  }
}

/* MARGIN-LEFT */
$class: 'mgl-';
@for $i from 0 through 60 {
  // for each $mgl-#{i}
  .#{$class}#{$i} {
    margin-left: #{$i}px;
  }
}

/* MARGIN-LEFT */
$class: 'mgb-';
@for $i from 0 through 60 {
  // for each $mgb-#{i}
  .#{$class}#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

/* PADDING-TOP */
$class: 'pdt-';
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-top: #{$i}px;
  }
}

/* PADDING-BOTTOM */
$class: 'pdb-';
@for $i from 0 through 60 {
  // for each $pb-t-#{i}
  .#{$class}#{$i} {
    padding-bottom: #{$i}px;
  }
}

/* PADDING-LEFT */
$class: 'pdl-';
@for $i from 0 through 60 {
  // for each $pb-t-#{i}
  .#{$class}#{$i} {
    padding-left: #{$i}px;
  }
}

/* PADDING-RIGHT */
$class: 'pdr-';
@for $i from 0 through 60 {
  // for each $pb-t-#{i}
  .#{$class}#{$i} {
    padding-right: #{$i}px;
  }
}

/* PADDING */
$class: 'pd-';
@for $i from 0 through 60 {
  // for each $pd-#{i}
  .#{$class}#{$i} {
    padding: #{$i}px;
  }
}

/* MARGIN-TOP-MOBILE */
$class: 'mb-mgt-';
@for $i from 0 through 60 {
  // for each $mb-mgt-#{i}
  .#{$class}#{$i} {
    @media only screen and (max-width: 767px) {
      margin-top: #{$i}px;
    }
  }
}

/* MARGIN-TOP-MOBILE */
$class: 'mb-mgt-768-';
@for $i from 0 through 60 {
  // for each $mb-mgt-#{i}
  .#{$class}#{$i} {
    @media only screen and (max-width: 768px) {
      margin-top: #{$i}px;
    }
  }
}

/* MARGIN-BOTTOM-MOBILE */
$class: 'mb-mgb-';
@for $i from 0 through 60 {
  // for each $mb-mgt-#{i}
  .#{$class}#{$i} {
    @media only screen and (max-width: 767px) {
      margin-bottom: #{$i}px;
    }
  }
}


.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.flex-content-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.ant-select-selection.ant-select-selection--single {
  border-radius: 4px;
}

.z-999 {
  z-index: 999;
}

.width-100pc {
  width: 100%;
}

.status-dot-processing {
  width: 6px;
  height: 6px;
  background: #b21cce;
  border-radius: 10px;
}

.status-dot-new {
  width: 6px;
  height: 6px;
  background: rgb(255, 193, 7);;
  border-radius: 10px;
}

.status-dot-refund {
  width: 6px;
  height: 6px;
  background: #52C41A;;
  border-radius: 10px;
}

.status-dot-accepted {
  width: 6px;
  height: 6px;
  background: rgb(17, 191, 213);;
  border-radius: 10px;
}

.status-dot-reject {
  width: 6px;
  height: 6px;
  background: rgb(233, 31, 99);
  border-radius: 10px;
}

.border-line {
  height: 1px;
  background-color: #DDEFEF;
}

.isMobileHidden {
  display: block;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.isPcHidden {
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.width-100mobile {
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.box-shadow {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
  background: #fff;
  border-radius: 4px;
}

.visited-link {
  &:visited {
    color: #AD79DA;
    background-color: transparent;
    text-decoration: none;
  }
}

.gap-3 {
  gap: 12px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot-active {
  @extend .dot;
  background: #52c234;  /* fallback for old browsers */
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}


@import "./helpers/index";

@import "./module/layout";

@import "./module/table.scss";
@import "./module/filter.scss";
@import "./module/form.scss";
@import "./module/drop-down-tab";

@import "./module/custom-antd";

@import "./module/common.scss";
@import "./module/list.scss";
@import "./module/comment.scss";
@import "./helpers/variables.scss";

@import "./ant-overide/index.scss";
@import "./module/select-badge";
@import "./module/floating-input";
@import "screen/staff.scss";
@import "screen/claims.scss";

// @tailwind base;
@tailwind components;
@tailwind utilities;