.ant-select-selector {
    border-radius: 4px;

    &:hover {
        //border: 0.5px #005A5A solid;
    }
}

.ant-btn {
    color: #007574;
    border-radius: 4px;

    &:hover {
        //border: 0.5px #005A5A solid;
        //color: #005A5A !important;
    }
}

.ant-input {
    border-radius: 4px;
}

.ant-form-item-label {
    color: $txt-color-blue;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ant-select-arrow {
    color: #333333;
}

.ant-picker-suffix {
    color: #707070;
}

.ant-picker-separator {
    color: #707070;
}

.ant-checkbox + span {
    padding-left: 6px;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #007574;
    border-color: #007574;
    border-radius: 2px;
}

.ant-checkbox {
    &:hover {
        // compiled to: .form__checkbox-label:hover:before
        border-color: #007574;
    }
}

.ant-modal-confirm .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ant-alert-warning {
    border-radius: 10px !important;
}

.ant-input-password {
    /* border: 0 !important; */

    .ant-input {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}

/* // .ant-input-affix-wrapper-focused {
//   border-color: #ffffff !important;
//   box-shadow: 0 0 0 0 #ffffff !important
// } */

.ant-picker-datetime-panel .ant-picker-date-panel {
    @media only screen and (max-width: 400px) {
        width: 100% !important;
    }
}

.ant-picker-time-panel-column {
    @media only screen and (max-width: 400px) {
        width: 48px !important;
    }
}

//.ant-tabs-tab {
//  margin-right: 5px !important;
//  margin-left: -1px !important;
//}
