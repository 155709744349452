.formgroup {
    margin-bottom: 20px; }

/************************************
              INPUT
************************************/
.ipgroup {
    position: relative; }

.lblform {
    font-family: "Roboto", sans-serif;
    color: #607d8b;
    font-size: 12px;
    display: block;
    margin-bottom: 10px; }

.iptext {
    width: 100%;
    height: 33px;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #000000;
    padding-left: 10px;
    padding-right: 35px; }
.iptext::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.iptext::-moz-placeholder {
    /* Firefox 19+ */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.iptext:-ms-input-placeholder {
    /* IE 10+ */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.iptext:-moz-placeholder {
    /* Firefox 18- */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.iptext:focus {
    border: 1px solid #008080; }
.iptext:hover {
    border: 1px solid #008080; }

.iptext-active {
    border: 1px solid #008080; }

.iptext-success {
    border: 1px solid #4caf50; }
.iptext-success::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #4caf50; }
.iptext-success::-moz-placeholder {
    /* Firefox 19+ */
    color: #4caf50; }
.iptext-success:-ms-input-placeholder {
    /* IE 10+ */
    color: #4caf50; }
.iptext-success:-moz-placeholder {
    /* Firefox 18- */
    color: #4caf50; }
.iptext-success:focus {
    border: 1px solid #4caf50; }
.iptext-success:hover {
    border: 1px solid #4caf50; }

.ipicon {
    position: absolute;
    font-size: 18px;
    right: 10px;
    top: 7px; }

.ipicon-success {
    color: #4caf50; }

.ip-error {
    color: #fc644c;
    font-size: 14px;
    line-height: 1.34;
    margin-top: 5px;
    padding-left: 10px;
    display: block;
    font-family: "Roboto", sans-serif; }

.iptext-error {
    border: 1px solid #F44336; }
.iptext-error::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fc644c; }
.iptext-error::-moz-placeholder {
    /* Firefox 19+ */
    color: #fc644c; }
.iptext-error:-ms-input-placeholder {
    /* IE 10+ */
    color: #fc644c; }
.iptext-error:-moz-placeholder {
    /* Firefox 18- */
    color: #fc644c; }

.iptext-disable {
    background-color: #fafafa;
    cursor: no-drop; }
.iptext-disable:hover {
    border-color: #dfe1e4; }
.iptext-disable:focus {
    border-color: #dfe1e4; }

/************************************
              CHECKBOX
************************************/
.checkgroup {
    position: relative;
    cursor: pointer; }
.checkgroup__ic {
    position: absolute;
    font-size: 20px;
    color: #000000;
    margin-right: 5px;
    top: -1px; }
.checkgroup__ic--active {
    color: #008080; }
.checkgroup__txt {
    font-size: 14px;
    line-height: 1.34;
    color: #000000;
    padding-left: 25px;
    font-family: "Roboto", sans-serif; }

.checkgroup-disable .checkgroup__ic, .checkgroup-disable .checkgroup__txt {
    color: #adadad; }

.filtercheckbox {
    position: relative;
    padding: 7px 6px 8px 35px;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer; }
.filtercheckbox__icon {
    position: absolute;
    top: 4px;
    left: 10px;
    font-size: 20px; }
.filtercheckbox__txt {
    font-size: 14px;
    color: #000000;
    font-family: "Roboto", sans-serif; }
.filtercheckbox:hover {
    background-color: #607d8b; }
.filtercheckbox:hover .filtercheckbox__txt, .filtercheckbox:hover .filtercheckbox__icon {
    color: #ffffff; }

.filtercheckbox--active {
    background-color: #607d8b; }
.filtercheckbox--active .filtercheckbox__txt, .filtercheckbox--active .filtercheckbox__icon {
    color: #ffffff; }
.filtercheckbox--active:hover {
    background-color: #ffffff; }
.filtercheckbox--active:hover .filtercheckbox__txt, .filtercheckbox--active:hover .filtercheckbox__icon {
    color: #000000; }

/************************************
              Radio Buttons
************************************/
.radiogroup {
    position: relative;
    cursor: pointer; }
.radiogroup__ic {
    position: absolute;
    font-size: 20px;
    color: #000000;
    margin-right: 5px;
    top: -1px; }
.radiogroup__ic--active {
    color: #008080; }
.radiogroup__txt {
    font-size: 14px;
    line-height: 1.34;
    color: #000000;
    padding-left: 25px;
    font-family: "Roboto", sans-serif; }

.radiogroup-disable .radiogroup__ic, .radiogroup-disable .radiogroup__txt {
    color: #adadad; }

/************************************
              Toggle Buttons
************************************/
.togglegroup {
    position: relative;
    cursor: pointer; }
.togglegroup__ic {
    position: absolute;
    font-size: 20px;
    color: #000000;
    margin-right: 5px;
    top: -1px; }
.togglegroup__ic--active {
    color: #008080; }
.togglegroup__txt {
    font-size: 14px;
    line-height: 1.34;
    color: #000000;
    padding-left: 25px;
    font-family: "Roboto", sans-serif; }

.togglegroup-disable .togglegroup__ic, .togglegroup-disable .togglegroup__txt {
    color: #adadad; }

/************************************
            Dropdown
************************************/
.dropdown__title {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
    width: 100%;
    height: 33px;
    line-height: 31px;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 35px; }
.dropdown__title span {
    color: #000000;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 0; }
.dropdown__title:focus {
    border: 1px solid #008080; }
.dropdown__title:hover {
    border: 1px solid #008080; }
.dropdown__list {
    width: 100%;
    background-color: #ffffff;
    z-index: 9;
    position: absolute;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    margin-top: 5px;
    -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    display: none; }
.dropdown__list ul li {
    padding: 15px; }
.dropdown__list ul li:hover {
    background-color: #f5f5f5;
    cursor: pointer; }
.dropdown__list ul li:hover span, .dropdown__list ul li:hover i {
    color: #008080; }
.dropdown__list ul li.selected {
    background-color: #f5f5f5;
    cursor: pointer; }
.dropdown__list ul li.selected span, .dropdown__list ul li.selected i {
    color: #008080; }
.dropdown__list ul li.active {
    background-color: #f5f5f5;
    cursor: pointer; }
.dropdown__list_txt {
    color: #000000;
    background-color: transparent!important;
    box-shadow: none!important;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    line-height: 1.34; }
.dropdown__list_icon {
    font-size: 18px; }
.dropdown__list_icon i.la-stop {
    color: #adadad; }
.dropdown__list_icon i.la-caret-square-o-down {
    color: #008080; }
.dropdown__list .wpbtn {
    text-align: right;
    margin: 10px; }

.dropdownactive .dropdown__title {
    border: 1px solid #008080; }
.dropdownactive .dropdown__list {
    display: block; }

.dropdownsearchbar__ip {
    position: relative;
    border-bottom: 1px solid #dfe1e4;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
.dropdownsearchbar__ip input {
    width: 100%;
    height: 38px;
    border: 0;
    padding-left: 13px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #000000;
    padding-right: 30px; }
.dropdownsearchbar__ip input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.dropdownsearchbar__ip input::-moz-placeholder {
    /* Firefox 19+ */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.dropdownsearchbar__ip input:-ms-input-placeholder {
    /* IE 10+ */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.dropdownsearchbar__ip input:-moz-placeholder {
    /* Firefox 18- */
    color: #adadad;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
.dropdownsearchbar__ip input:focus {
    border: 0 !important; }
.dropdownsearchbar__ip input:hover {
    border: 0 !important; }
.dropdownsearchbar__ip--ic {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: #adadad; }
.dropdownsearchbar__boxlist {
    max-height: 250px;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px; }
.dropdownsearchbar__boxlist::-webkit-scrollbar-track {
    background-color: #dfe1e4; }
.dropdownsearchbar__boxlist::-webkit-scrollbar {
    width: 4px;
    background-color: #008080; }
.dropdownsearchbar__boxlist::-webkit-scrollbar-thumb {
    background-color: #008080;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px; }

.dropdowncheckbox .dropdown__list li {
    position: relative; }
.dropdowncheckbox .dropdown__list_icon {
    position: absolute;
    top: 13px; }
.dropdowncheckbox .dropdown__list_txt {
    padding-left: 25px; }

.dropdowntooltip .dropdown__title {
    border: 0;
    color: #2196f3; }
.dropdowntooltip .dropdown__title span {
    color: #2196f3;
    position: inherit; }
.dropdowntooltip__list:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent #dddddd transparent; }
.dropdowntooltip__list:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 51px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent; }

.dropdowncheckboxtooltip .dropdown__title {
    text-align: right; }
.dropdowncheckboxtooltip .dropdown__title span {
    font-size: 20px;
    color: #607d8b; }
.dropdowncheckboxtooltip .dropdowntooltip__list:before {
    left: inherit;
    right: 37px; }
.dropdowncheckboxtooltip .dropdowntooltip__list:after {
    left: inherit;
    right: 38px; }

.notification-taobao {
    margin-bottom: 20px; }
.notification-taobao .Toastify__toast {
    position: relative;
    width: 100%;
    min-height: auto;
    border: 0px;
    padding: 0;
    display: inline-block;
    overflow: initial; }
.notification-taobao .Toastify__toast .Toastify__toast-body {
    min-height: 33px;
    font-size: 14px;
    padding: 10px 30px 10px 40px;
    border: 1px solid;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    line-height: 1.34;
    font-family: "Roboto", sans-serif;
    -webkit-box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1); }
.notification-taobao .Toastify__toast .Toastify__toast-body i {
    position: absolute;
    left: 10px;
    top: 12px;
    font-size: 18px; }
.notification-taobao .Toastify__toast .Toastify__close-button {
    position: absolute;
    right: 10px;
    top: 11px;
    background: transparent;
    border: 0px;
    cursor: pointer; }
.notification-taobao .Toastify__toast--success .Toastify__toast-body {
    border-color: #4caf50;
    color: #4caf50;
    background-color: #fff; }
.notification-taobao .Toastify__toast--success .Toastify__close-button {
    color: #4caf50; }
.notification-taobao .Toastify__toast--error .Toastify__toast-body {
    border-color: #e9202d;
    color: #e9202d;
    background-color: #fff; }
.notification-taobao .Toastify__toast--error .Toastify__close-button {
    color: #e9202d; }
.notification-taobao .Toastify__toast--info .Toastify__toast-body {
    border-color: #2196f3;
    color: #2196f3;
    background-color: #f4fafe; }
.notification-taobao .Toastify__toast--info .Toastify__close-button {
    color: #2196f3; }
.notification-taobao .Toastify__toast--info2 .Toastify__toast-body {
    border-color: #dfe1e4;
    color: #2196f3;
    background-color: #ffffff; }
.notification-taobao .Toastify__toast--info2 .Toastify__close-button {
    color: #666666; }
.notification-taobao .Toastify__toast--warning .Toastify__toast-body {
    border-color: #ff9800;
    color: #ff9800;
    background-color: #fffaf2; }
.notification-taobao .Toastify__toast--warning .Toastify__close-button {
    color: #ff9800; }

.Toastify__toast-container {
    z-index: 999;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff; }

.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }

.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }

.Toastify__toast-container--top-right {
    top: 70px;
    right: 10px;
}

.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }

.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }

.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0; }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 0; }

    .Toastify__toast-container--bottom-left,
    .Toastify__toast-container--bottom-center,
    .Toastify__toast-container--bottom-right {
        bottom: 0; }

    .Toastify__toast-container--rtl {
        right: 0;
        left: initial; } }
.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
    background-color: #fff; }

.Toastify__toast--rtl {
    direction: rtl; }

.Toastify__toast--default {
    background: #fff;
    color: #aaa; }

.Toastify__toast--info {
    color: #2196f3;
    border: 1px solid #2196f3; }

.Toastify__toast--info .Toastify__close-button {
    color: #2196f3; }

.Toastify__toast--success {
    color: #4caf50;
    border: 1px solid #4caf50; }

.Toastify__toast--success .Toastify__close-button {
    color: #4caf50; }

.Toastify__toast--warning {
    color: #ff9800;
    border: 1px solid #ff9800; }

.Toastify__toast--warning .Toastify__close-button {
    color: #ff9800; }

.Toastify__toast--error {
    color: #e91e63;
    border: 1px solid #e91e63; }

.Toastify__toast--error .Toastify__close-button {
    color: #e91e63; }

.Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1;
    flex: 1; }

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        margin-bottom: 0; } }
.Toastify__close-button {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
    align-self: flex-start; }

.Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 1; }

@keyframes Toastify__trackProgress {
    0% {
        width: 100%; }
    100% {
        width: 0; } }
.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 5px;
    z-index: 9999;
    opacity: 0.7;
    animation: Toastify__trackProgress linear 1;
    background-color: rgba(255, 255, 255, 0.7); }

.Toastify__progress-bar--rtl {
    right: 0;
    left: initial; }

.Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0); }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0); }
    75% {
        transform: translate3d(10px, 0, 0); }
    90% {
        transform: translate3d(-5px, 0, 0); }
    to {
        transform: none; } }
@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0); }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0); } }
@keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0); }
    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0); }
    75% {
        transform: translate3d(-10px, 0, 0); }
    90% {
        transform: translate3d(5px, 0, 0); }
    to {
        transform: none; } }
@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0); }
    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0); } }
@keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0); }
    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0); }
    75% {
        transform: translate3d(0, 10px, 0); }
    90% {
        transform: translate3d(0, -5px, 0); }
    to {
        transform: translate3d(0, 0, 0); } }
@keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0); }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0); }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0); } }
@keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0); }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0); }
    75% {
        transform: translate3d(0, -10px, 0); }
    90% {
        transform: translate3d(0, 5px, 0); }
    to {
        transform: none; } }
@keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0); }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0); }
    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0); } }
.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
    animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
    animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
    animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
    animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
    animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3); }
    50% {
        opacity: 1; } }
@keyframes Toastify__zoomOut {
    from {
        opacity: 1; }
    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3); }
    to {
        opacity: 0; } }
.Toastify__zoom-enter {
    animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
    animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0; }
    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in; }
    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1; }
    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
    to {
        transform: perspective(400px); } }
@keyframes Toastify__flipOut {
    from {
        transform: perspective(400px); }
    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1; }
    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0; } }
.Toastify__flip-enter {
    animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
    animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }
@keyframes Toastify__slideInLeft {
    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }
@keyframes Toastify__slideInUp {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }
@keyframes Toastify__slideInDown {
    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible; }
    to {
        transform: translate3d(0, 0, 0); } }
@keyframes Toastify__slideOutRight {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0); } }
@keyframes Toastify__slideOutLeft {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0); } }
@keyframes Toastify__slideOutDown {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0); } }
@keyframes Toastify__slideOutUp {
    from {
        transform: translate3d(0, 0, 0); }
    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0); } }
.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
    animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
    animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
    animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
    animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
    animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
.breadcrumbtaobao ul {
    display: flex; }
.breadcrumbtaobao__icon {
    font-size: 14px;
    color: #008080;
    margin-right: 10px;
    display: inline-block;
    padding-top: 3px; }
.breadcrumbtaobao__txt {
    font-size: 14px;
    color: #008080;
    margin-right: 10px;
    display: inline-block;
    padding-top: 3px;
    font-family: "Roboto", sans-serif; }
.breadcrumbtaobao__txt span {
    margin-right: 6px; }
.breadcrumbtaobao__txt:hover {
    color: #008080; }
.breadcrumbtaobao .txtlast {
    font-size: 18px;
    color: #000000;
    padding-top: 0; }

.thumbavatar__box {
    display: flex; }
.thumbavatar--large img {
    width: 40px;
    height: 40px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    margin-right: 10px; }
.thumbavatar--large-more {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #dfe1e4;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    display: block;
    text-align: center;
    cursor: pointer; }
.thumbavatar--medium img {
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin-right: 10px; }
.thumbavatar--medium-more {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: #dfe1e4;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    display: block;
    text-align: center;
    cursor: pointer; }
.thumbavatar--small img {
    width: 25px;
    height: 25px;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin-right: 10px; }
.thumbavatar--small-more {
    width: 25px;
    height: 25px;
    line-height: 25px;
    background-color: #dfe1e4;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    display: block;
    text-align: center;
    cursor: pointer; }

.tabsibizan {
    display: flex; }
.tabsibizan li {
    margin-right: 10px; }
.tabsibizan li a {
    position: relative;
    font-size: 12px;
    display: block;
    background-color: #edeff2;
    color: #000000;
    font-family: "Roboto", sans-serif;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    height: 30px;
    line-height: 30px; }
.tabsibizan li a:hover {
    background-color: #008080;
    color: #fff; }
.tabsibizan li a:hover span {
    color: #fff; }
.tabsibizan li a.active {
    background-color: #008080;
    color: #fff; }
.tabsibizan__number {
    position: absolute;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #ffc107;
    color: #fff;
    font-size: 12px;
    line-height: 1.34;
    font-family: "Roboto", sans-serif;
    padding: 2px 6px 2px 7px;
    top: -9px;
    right: -9px; }

.btn {
    height: 33px;
    line-height: 33px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    padding: 0 23px;
    /*  border: 0px;
      background-color: transparent;*/
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px; }
.btn--green3 {
    color: #fff;
    background: #008080;
    /* Old browsers */
    background: -moz-linear-gradient(top, #008080 0%, #008080 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #008080 0%, #008080 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #008080 0%, #008080 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008080', endColorstr='#036565',GradientType=0 );
    /* IE6-9 */ }
.btn--green3:hover {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #036565;
    /* Old browsers */
    background: -moz-linear-gradient(top, #036565 0%, #036565 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #036565 0%, #036565 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #036565 0%, #036565 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008080', endColorstr='#008080',GradientType=0 );
    /* IE6-9 */
    color: #fff; }
.btn--green {
    color: #ffffff;
    background: #4caf50;
    /* Old browsers */
    background: -moz-linear-gradient(top, #8bc34a 0%, #4caf50 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #8bc34a 0%, #4caf50 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #8bc34a 0%, #4caf50 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc34a', endColorstr='#4caf50',GradientType=0 );
    /* IE6-9 */ }
.btn--green:hover {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #fc644c;
    /* Old browsers */
    background: -moz-linear-gradient(top, #4caf50 0%, #4caf50 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #4caf50 0%, #4caf50 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4caf50 0%, #4caf50 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4caf50', endColorstr='#4caf50',GradientType=0 );
    /* IE6-9 */
    color: #ffffff; }
.btn--gray {
    color: #ffffff;
    background: #4caf50;
    /* Old browsers */
    background: -moz-linear-gradient(top, #dedede 0%, #b1b1b1 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #dedede 0%, #b1b1b1 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #dedede 0%, #b1b1b1 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#b1b1b1',GradientType=0 );
    /* IE6-9 */ }
.btn--gray:hover {
    -webkit-transition: 0.2s;
    -khtml-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #fc644c;
    /* Old browsers */
    background: -moz-linear-gradient(top, #b1b1b1 0%, #b1b1b1 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #b1b1b1 0%, #b1b1b1 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #b1b1b1 0%, #b1b1b1 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b1b1b1', endColorstr='#b1b1b1',GradientType=0 );
    /* IE6-9 */
    color: #ffffff; }
.btn--huy {
    background-color: transparent;
    padding: 0 10px !important; }
.btn__line {
    border: 1px solid #dfe1e4; }
.btn__line--gray:hover {
    background-color: #ebebeb; }
.btn__bold--gray {
    background-color: #edeff2;
    color: #000000; }
.btn__bold--gray:hover {
    background-color: #e1e3e6;
    color: #000000; }
.btn--disable {
    opacity: 0.5;
    cursor: no-drop; }
.btn--disable:hover {
    background: transparent; }
.btn--disable:hover i {
    color: #000000; }
.btn:disabled {
    cursor: no-drop; }
.btn:focus {
    outline: none !important;
    box-shadow: none !important; }

.pagination {
    display: flex; }
.pagination__txt {
    padding-top: 10px;
    padding-right: 15px; }
.pagination__txt span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #000000; }
.pagination__wpicon {
    display: inline-block;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    margin-right: 5px;
    color: #000000;
    font-size: 20px; }

.pagination-number li a {
    font-size: 16px;
    color: #666666;
    font-family: "Roboto", sans-serif;
    margin-left: 0; }
.pagination-number li a span {
    font-size: 14px;
    color: #000000; }
.pagination-number li a:hover {
    background-color: #fce7e3;
    border: 1px solid #fc8f7e;
    color: #008080; }
.pagination-number li a.active {
    background-color: #fce7e3;
    border: 1px solid #fc8f7e;
    color: #008080; }

.date-picker-container {
    position: relative; }
.date-picker-container .react-datepicker-wrapper {
    width: 100%; }
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #dfe1e4; }
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
    height: 31px;
    border: 0;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #000000;
    padding-left: 10px; }
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container:focus {
    border: 1px solid #008080; }
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container:hover {
    border: 1px solid #008080; }
.date-picker-container .icon-calendar {
    right: 1px;
    top: 1px;
    position: absolute;
    background-color: #ebebeb;
    width: 33px;
    height: 31px;
    line-height: 31px;
    color: #adadad;
    font-size: 18px;
    text-align: center;
    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -moz-border-radius-bottomright: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }

.react-datepicker {
    border: 1px solid #dfe1e4; }

.react-datepicker__header {
    padding-top: 0; }

.react-datepicker__current-month {
    background-color: #ffffff;
    color: #000000;
    padding: 10px;
    text-transform: capitalize; }

.react-datepicker__navigation {
    font-family: "Roboto", sans-serif; }

.react-datepicker__navigation--previous {
    border-right-color: #adadad; }

.react-datepicker__navigation--next {
    border-left-color: #adadad; }

.react-datepicker__header {
    border-bottom: 0; }

.react-datepicker__day-names {
    background-color: #fafafa; }

.react-datepicker__day-name {
    color: #adadad;
    margin: 7px; }

.react-datepicker__day, .react-datepicker__time-name {
    color: #000000;
    font-family: "Roboto", sans-serif;
    margin: 7px; }

.react-datepicker__day:hover {
    -webkit-border-radius: 50px;
    -khtml-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px; }

.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
    background-color: #ffeb3b;
    -webkit-border-radius: 50px;
    -khtml-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px; }
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--selected:hover {
    background-color: #ffeb3b;
    -webkit-border-radius: 50px;
    -khtml-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px; }

.react-datepicker__day--disabled {
    color: #adadad !important; }

.datepickertxt .react-datepicker-wrapper .react-datepicker__input-container {
    border: 0px !important; }
.datepickertxt .react-datepicker-wrapper .react-datepicker__input-container .react-datepicker-ignore-onclickoutside {
    position: relative;
    width: 100%;
    border: 0;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #000000; }
.datepickertxt .react-datepicker-wrapper .react-datepicker__input-container .react-datepicker-ignore-onclickoutside .la-calendar {
    top: 3px;
    right: -25px;
    position: absolute;
    color: #adadad;
    font-size: 18px;
    text-align: center;
    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -moz-border-radius-bottomright: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }

.popupbox {
    z-index: 99991;
    border: 1px solid #dfe1e4;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding: 30px;
    background-color: #ffffff; }
.popupbox__close {
    position: absolute;
    width: 24px;
    height: 24px;
    line-height: 24px;
    top: -5px;
    right: -8px;
    border: 1px solid #e0e2e5;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    background-color: #edeff2; }
.popupbox__close span {
    display: block; }
.popupbox__title {
    text-align: center;
    color: #008080;
    line-height: 1.34;
    font-size: 20px;
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif; }
.popupbox__txt {
    font-size: 14px;
    line-height: 1.34;
    color: #000000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-align: center;
    display: block; }
.popupbox .wp-btn {
    text-align: center;
    margin-top: 30px;
    display: flex;
    justify-content: center; }

.popupboxfixed {
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

@media only screen and (max-width: 812px) {
    .popupboxfixed {
        width: 85%; } }

/*# sourceMappingURL=main.css.map */
